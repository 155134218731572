/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  Datetime: any;
  UUID: any;
};

/** Banner targeting settings for a given Placements.IO line item. */
export type BannerTargeting = {
  __typename?: 'BannerTargeting';
  /** End of date range (Eastern Time) during which the targeting will be made available to Google Ad Manager. */
  endsAt: Scalars['Datetime'];
  id: Scalars['Int'];
  placementsCampaignId?: Maybe<Scalars['String']>;
  placementsLineItemId: Scalars['String'];
  /** Beginning of date range (Eastern Time) during which the targeting will be made available to Google Ad Manager. */
  startsAt: Scalars['Datetime'];
  /** Reads a single `TargetList` that is related to this `BannerTargeting`. */
  targetList?: Maybe<TargetList>;
  /** ID of the target list that should be made available to Google Ad Manager for ad targeting. */
  targetListId: Scalars['Int'];
};

/**
 * A condition to be used against `BannerTargeting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BannerTargetingCondition = {
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startsAt` field. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `targetListId` field. */
  targetListId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BannerTargeting` object types. All fields are combined with a logical ‘and.’ */
export type BannerTargetingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BannerTargetingFilter>>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BannerTargetingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BannerTargetingFilter>>;
  /** Filter by the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startsAt` field. */
  startsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetListId` field. */
  targetListId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `BannerTargeting` */
export type BannerTargetingInput = {
  /** End of date range (Eastern Time) during which the targeting will be made available to Google Ad Manager. */
  endsAt: Scalars['Datetime'];
  id?: InputMaybe<Scalars['Int']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  placementsLineItemId: Scalars['String'];
  /** Beginning of date range (Eastern Time) during which the targeting will be made available to Google Ad Manager. */
  startsAt: Scalars['Datetime'];
  /** ID of the target list that should be made available to Google Ad Manager for ad targeting. */
  targetListId: Scalars['Int'];
};

/** A connection to a list of `BannerTargeting` values. */
export type BannerTargetingsConnection = {
  __typename?: 'BannerTargetingsConnection';
  /** A list of edges which contains the `BannerTargeting` and cursor to aid in pagination. */
  edges: Array<BannerTargetingsEdge>;
  /** A list of `BannerTargeting` objects. */
  nodes: Array<BannerTargeting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BannerTargeting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BannerTargeting` edge in the connection. */
export type BannerTargetingsEdge = {
  __typename?: 'BannerTargetingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BannerTargeting` at the end of the edge. */
  node: BannerTargeting;
};

/** Methods to use when ordering `BannerTargeting`. */
export enum BannerTargetingsOrderBy {
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PlacementsCampaignIdAsc = 'PLACEMENTS_CAMPAIGN_ID_ASC',
  PlacementsCampaignIdDesc = 'PLACEMENTS_CAMPAIGN_ID_DESC',
  PlacementsLineItemIdAsc = 'PLACEMENTS_LINE_ITEM_ID_ASC',
  PlacementsLineItemIdDesc = 'PLACEMENTS_LINE_ITEM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartsAtAsc = 'STARTS_AT_ASC',
  StartsAtDesc = 'STARTS_AT_DESC',
  TargetListIdAsc = 'TARGET_LIST_ID_ASC',
  TargetListIdDesc = 'TARGET_LIST_ID_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Table for managing the relationship between campaigns and labels. */
export type CampaignLabel = {
  __typename?: 'CampaignLabel';
  /** Reads a single `Label` that is related to this `CampaignLabel`. */
  label?: Maybe<Label>;
  labelId: Scalars['UUID'];
  placementsCampaignId: Scalars['String'];
};

/**
 * A condition to be used against `CampaignLabel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CampaignLabelCondition = {
  /** Checks for equality with the object’s `labelId` field. */
  labelId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `CampaignLabel` object types. All fields are combined with a logical ‘and.’ */
export type CampaignLabelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignLabelFilter>>;
  /** Filter by the object’s `labelId` field. */
  labelId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignLabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignLabelFilter>>;
  /** Filter by the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `CampaignLabel` */
export type CampaignLabelInput = {
  labelId: Scalars['UUID'];
  placementsCampaignId: Scalars['String'];
};

/** Represents an update to a `CampaignLabel`. Fields that are set will be updated. */
export type CampaignLabelPatch = {
  labelId?: InputMaybe<Scalars['UUID']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `CampaignLabel` values. */
export type CampaignLabelsConnection = {
  __typename?: 'CampaignLabelsConnection';
  /** A list of edges which contains the `CampaignLabel` and cursor to aid in pagination. */
  edges: Array<CampaignLabelsEdge>;
  /** A list of `CampaignLabel` objects. */
  nodes: Array<CampaignLabel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CampaignLabel` edge in the connection. */
export type CampaignLabelsEdge = {
  __typename?: 'CampaignLabelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CampaignLabel` at the end of the edge. */
  node: CampaignLabel;
};

/** Methods to use when ordering `CampaignLabel`. */
export enum CampaignLabelsOrderBy {
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  Natural = 'NATURAL',
  PlacementsCampaignIdAsc = 'PLACEMENTS_CAMPAIGN_ID_ASC',
  PlacementsCampaignIdDesc = 'PLACEMENTS_CAMPAIGN_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Clicks creatives. */
export type ClicksCreative = {
  __typename?: 'ClicksCreative';
  /** Sponsor/Company/Brand name for the Click. Optional. */
  companyName: Scalars['String'];
  /** If true, display the job number together with the Click. */
  displayJobNumber: Scalars['Boolean'];
  /** End date and time when this Click should stop being displayed. Note that already delivered Clicks (i.e. links in email) should continue to work after this date, this is just a cutoff for new displays. */
  endsAt: Scalars['Datetime'];
  hasActivityData?: Maybe<Scalars['Boolean']>;
  hideOnDaysList?: Maybe<Array<Maybe<DayOfWeekType>>>;
  /** ID for the creative. */
  id: Scalars['Int'];
  isApproved?: Maybe<Scalars['Boolean']>;
  /** The optional job number ID (a string) that is sometimes displayed with the Click. */
  jobNumber?: Maybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name: Scalars['String'];
  placementsCampaignId?: Maybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId: Scalars['String'];
  priority: Scalars['Int'];
  professionIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `Profession`. */
  professions: ProfessionsConnection;
  /** Reads and enables pagination through a set of `Specialty`. */
  specialties: SpecialtiesConnection;
  specialtyIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Start date and time when this Click should start to be displayed. */
  startsAt: Scalars['Datetime'];
  targetListIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `TargetList`. */
  targetLists: TargetListsConnection;
  targetingType?: Maybe<ClicksCreativeTargetingType>;
  /** Text of the click - this is the actual clickable text that is displayed */
  title: Scalars['String'];
  /** Destination URL to redirect to if the Click is clicked. */
  url: Scalars['String'];
};


/** Clicks creatives. */
export type ClicksCreativeHideOnDaysListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeProfessionIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeSpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeSpecialtyIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeTargetListIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Clicks creatives. */
export type ClicksCreativeTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ClicksCreative` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClicksCreativeCondition = {
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayJobNumber` field. */
  displayJobNumber?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `jobNumber` field. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startsAt` field. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `targetingType` field. */
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ClicksCreative` object types. All fields are combined with a logical ‘and.’ */
export type ClicksCreativeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClicksCreativeFilter>>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayJobNumber` field. */
  displayJobNumber?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `hasActivityData` field. */
  hasActivityData?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobNumber` field. */
  jobNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClicksCreativeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClicksCreativeFilter>>;
  /** Filter by the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `startsAt` field. */
  startsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetingType` field. */
  targetingType?: InputMaybe<ClicksCreativeTargetingTypeFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ClicksCreative` */
export type ClicksCreativeInput = {
  /** Sponsor/Company/Brand name for the Click. Optional. */
  companyName: Scalars['String'];
  /** If true, display the job number together with the Click. */
  displayJobNumber?: InputMaybe<Scalars['Boolean']>;
  /** End date and time when this Click should stop being displayed. Note that already delivered Clicks (i.e. links in email) should continue to work after this date, this is just a cutoff for new displays. */
  endsAt: Scalars['Datetime'];
  /** ID for the creative. */
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** The optional job number ID (a string) that is sometimes displayed with the Click. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name: Scalars['String'];
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  /** Start date and time when this Click should start to be displayed. */
  startsAt: Scalars['Datetime'];
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  /** Text of the click - this is the actual clickable text that is displayed */
  title: Scalars['String'];
  /** Destination URL to redirect to if the Click is clicked. */
  url: Scalars['String'];
};

/** Represents an update to a `ClicksCreative`. Fields that are set will be updated. */
export type ClicksCreativePatch = {
  /** Sponsor/Company/Brand name for the Click. Optional. */
  companyName?: InputMaybe<Scalars['String']>;
  /** If true, display the job number together with the Click. */
  displayJobNumber?: InputMaybe<Scalars['Boolean']>;
  /** End date and time when this Click should stop being displayed. Note that already delivered Clicks (i.e. links in email) should continue to work after this date, this is just a cutoff for new displays. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** ID for the creative. */
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** The optional job number ID (a string) that is sometimes displayed with the Click. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name?: InputMaybe<Scalars['String']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  /** Start date and time when this Click should start to be displayed. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  /** Text of the click - this is the actual clickable text that is displayed */
  title?: InputMaybe<Scalars['String']>;
  /** Destination URL to redirect to if the Click is clicked. */
  url?: InputMaybe<Scalars['String']>;
};

export enum ClicksCreativeTargetingType {
  RunOfSpecialty = 'RUN_OF_SPECIALTY',
  TargetList = 'TARGET_LIST'
}

/** A filter to be used against ClicksCreativeTargetingType fields. All fields are combined with a logical ‘and.’ */
export type ClicksCreativeTargetingTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ClicksCreativeTargetingType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ClicksCreativeTargetingType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ClicksCreativeTargetingType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ClicksCreativeTargetingType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ClicksCreativeTargetingType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ClicksCreativeTargetingType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ClicksCreativeTargetingType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ClicksCreativeTargetingType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ClicksCreativeTargetingType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ClicksCreativeTargetingType>>;
};

/** A connection to a list of `ClicksCreative` values. */
export type ClicksCreativesConnection = {
  __typename?: 'ClicksCreativesConnection';
  /** A list of edges which contains the `ClicksCreative` and cursor to aid in pagination. */
  edges: Array<ClicksCreativesEdge>;
  /** A list of `ClicksCreative` objects. */
  nodes: Array<ClicksCreative>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClicksCreative` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClicksCreative` edge in the connection. */
export type ClicksCreativesEdge = {
  __typename?: 'ClicksCreativesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ClicksCreative` at the end of the edge. */
  node: ClicksCreative;
};

/** Methods to use when ordering `ClicksCreative`. */
export enum ClicksCreativesOrderBy {
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  DisplayJobNumberAsc = 'DISPLAY_JOB_NUMBER_ASC',
  DisplayJobNumberDesc = 'DISPLAY_JOB_NUMBER_DESC',
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  JobNumberAsc = 'JOB_NUMBER_ASC',
  JobNumberDesc = 'JOB_NUMBER_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PlacementsCampaignIdAsc = 'PLACEMENTS_CAMPAIGN_ID_ASC',
  PlacementsCampaignIdDesc = 'PLACEMENTS_CAMPAIGN_ID_DESC',
  PlacementsLineItemIdAsc = 'PLACEMENTS_LINE_ITEM_ID_ASC',
  PlacementsLineItemIdDesc = 'PLACEMENTS_LINE_ITEM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  StartsAtAsc = 'STARTS_AT_ASC',
  StartsAtDesc = 'STARTS_AT_DESC',
  TargetingTypeAsc = 'TARGETING_TYPE_ASC',
  TargetingTypeDesc = 'TARGETING_TYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

/** All input for the create `BannerTargeting` mutation. */
export type CreateBannerTargetingInput = {
  /** The `BannerTargeting` to be created by this mutation. */
  bannerTargeting: BannerTargetingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BannerTargeting` mutation. */
export type CreateBannerTargetingPayload = {
  __typename?: 'CreateBannerTargetingPayload';
  /** The `BannerTargeting` that was created by this mutation. */
  bannerTargeting?: Maybe<BannerTargeting>;
  /** An edge for our `BannerTargeting`. May be used by Relay 1. */
  bannerTargetingEdge?: Maybe<BannerTargetingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TargetList` that is related to this `BannerTargeting`. */
  targetList?: Maybe<TargetList>;
};


/** The output of our create `BannerTargeting` mutation. */
export type CreateBannerTargetingPayloadBannerTargetingEdgeArgs = {
  orderBy?: InputMaybe<Array<BannerTargetingsOrderBy>>;
};

/** All input for the create `CampaignLabel` mutation. */
export type CreateCampaignLabelInput = {
  /** The `CampaignLabel` to be created by this mutation. */
  campaignLabel: CampaignLabelInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `CampaignLabel` mutation. */
export type CreateCampaignLabelPayload = {
  __typename?: 'CreateCampaignLabelPayload';
  /** The `CampaignLabel` that was created by this mutation. */
  campaignLabel?: Maybe<CampaignLabel>;
  /** An edge for our `CampaignLabel`. May be used by Relay 1. */
  campaignLabelEdge?: Maybe<CampaignLabelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Label` that is related to this `CampaignLabel`. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `CampaignLabel` mutation. */
export type CreateCampaignLabelPayloadCampaignLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignLabelsOrderBy>>;
};

/** All input for the create `ClicksCreative` mutation. */
export type CreateClicksCreativeInput = {
  /** The `ClicksCreative` to be created by this mutation. */
  clicksCreative: ClicksCreativeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClicksCreative` mutation. */
export type CreateClicksCreativePayload = {
  __typename?: 'CreateClicksCreativePayload';
  /** The `ClicksCreative` that was created by this mutation. */
  clicksCreative?: Maybe<ClicksCreative>;
  /** An edge for our `ClicksCreative`. May be used by Relay 1. */
  clicksCreativeEdge?: Maybe<ClicksCreativesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `ClicksCreative` mutation. */
export type CreateClicksCreativePayloadClicksCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<ClicksCreativesOrderBy>>;
};

/** All input for the create `FmaCreative` mutation. */
export type CreateFmaCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FmaCreative` to be created by this mutation. */
  fmaCreative: FmaCreativeInput;
};

/** The output of our create `FmaCreative` mutation. */
export type CreateFmaCreativePayload = {
  __typename?: 'CreateFmaCreativePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FmaCreative` that was created by this mutation. */
  fmaCreative?: Maybe<FmaCreative>;
  /** An edge for our `FmaCreative`. May be used by Relay 1. */
  fmaCreativeEdge?: Maybe<FmaCreativesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `FmaCreative` mutation. */
export type CreateFmaCreativePayloadFmaCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<FmaCreativesOrderBy>>;
};

/** All input for the create `Label` mutation. */
export type CreateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Label` to be created by this mutation. */
  label: LabelInput;
};

/** The output of our create `Label` mutation. */
export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Label` that was created by this mutation. */
  label?: Maybe<Label>;
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge?: Maybe<LabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Label` mutation. */
export type CreateLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};

/** All input for the create `LineItemLabel` mutation. */
export type CreateLineItemLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LineItemLabel` to be created by this mutation. */
  lineItemLabel: LineItemLabelInput;
};

/** The output of our create `LineItemLabel` mutation. */
export type CreateLineItemLabelPayload = {
  __typename?: 'CreateLineItemLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Label` that is related to this `LineItemLabel`. */
  label?: Maybe<Label>;
  /** The `LineItemLabel` that was created by this mutation. */
  lineItemLabel?: Maybe<LineItemLabel>;
  /** An edge for our `LineItemLabel`. May be used by Relay 1. */
  lineItemLabelEdge?: Maybe<LineItemLabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LineItemLabel` mutation. */
export type CreateLineItemLabelPayloadLineItemLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LineItemLabelsOrderBy>>;
};

/** All input for the create `Profession` mutation. */
export type CreateProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Profession` to be created by this mutation. */
  profession: ProfessionInput;
};

/** The output of our create `Profession` mutation. */
export type CreateProfessionPayload = {
  __typename?: 'CreateProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profession` that was created by this mutation. */
  profession?: Maybe<Profession>;
  /** An edge for our `Profession`. May be used by Relay 1. */
  professionEdge?: Maybe<ProfessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Profession` mutation. */
export type CreateProfessionPayloadProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfessionsOrderBy>>;
};

/** All input for the create `Specialty` mutation. */
export type CreateSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Specialty` to be created by this mutation. */
  specialty: SpecialtyInput;
};

/** The output of our create `Specialty` mutation. */
export type CreateSpecialtyPayload = {
  __typename?: 'CreateSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Specialty` that was created by this mutation. */
  specialty?: Maybe<Specialty>;
  /** An edge for our `Specialty`. May be used by Relay 1. */
  specialtyEdge?: Maybe<SpecialtiesEdge>;
};


/** The output of our create `Specialty` mutation. */
export type CreateSpecialtyPayloadSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SpecialtiesOrderBy>>;
};

/** All input for the create `SponsoredContentTeaser` mutation. */
export type CreateSponsoredContentTeaserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SponsoredContentTeaser` to be created by this mutation. */
  sponsoredContentTeaser: SponsoredContentTeaserInput;
};

/** The output of our create `SponsoredContentTeaser` mutation. */
export type CreateSponsoredContentTeaserPayload = {
  __typename?: 'CreateSponsoredContentTeaserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SponsoredContentTeaser` that was created by this mutation. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** An edge for our `SponsoredContentTeaser`. May be used by Relay 1. */
  sponsoredContentTeaserEdge?: Maybe<SponsoredContentTeasersEdge>;
};


/** The output of our create `SponsoredContentTeaser` mutation. */
export type CreateSponsoredContentTeaserPayloadSponsoredContentTeaserEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeasersOrderBy>>;
};

/** All input for the create `SponsoredContentTeaserProfession` mutation. */
export type CreateSponsoredContentTeaserProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SponsoredContentTeaserProfession` to be created by this mutation. */
  sponsoredContentTeaserProfession: SponsoredContentTeaserProfessionInput;
};

/** The output of our create `SponsoredContentTeaserProfession` mutation. */
export type CreateSponsoredContentTeaserProfessionPayload = {
  __typename?: 'CreateSponsoredContentTeaserProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Profession` that is related to this `SponsoredContentTeaserProfession`. */
  profession?: Maybe<Profession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserProfession` that was created by this mutation. */
  sponsoredContentTeaserProfession?: Maybe<SponsoredContentTeaserProfession>;
  /** An edge for our `SponsoredContentTeaserProfession`. May be used by Relay 1. */
  sponsoredContentTeaserProfessionEdge?: Maybe<SponsoredContentTeaserProfessionsEdge>;
};


/** The output of our create `SponsoredContentTeaserProfession` mutation. */
export type CreateSponsoredContentTeaserProfessionPayloadSponsoredContentTeaserProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};

/** All input for the create `SponsoredContentTeaserSpecialty` mutation. */
export type CreateSponsoredContentTeaserSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SponsoredContentTeaserSpecialty` to be created by this mutation. */
  sponsoredContentTeaserSpecialty: SponsoredContentTeaserSpecialtyInput;
};

/** The output of our create `SponsoredContentTeaserSpecialty` mutation. */
export type CreateSponsoredContentTeaserSpecialtyPayload = {
  __typename?: 'CreateSponsoredContentTeaserSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Specialty` that is related to this `SponsoredContentTeaserSpecialty`. */
  specialty?: Maybe<Specialty>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserSpecialty` that was created by this mutation. */
  sponsoredContentTeaserSpecialty?: Maybe<SponsoredContentTeaserSpecialty>;
  /** An edge for our `SponsoredContentTeaserSpecialty`. May be used by Relay 1. */
  sponsoredContentTeaserSpecialtyEdge?: Maybe<SponsoredContentTeaserSpecialtiesEdge>;
};


/** The output of our create `SponsoredContentTeaserSpecialty` mutation. */
export type CreateSponsoredContentTeaserSpecialtyPayloadSponsoredContentTeaserSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};

/** All input for the create `SponsoredContentTeaserTargetList` mutation. */
export type CreateSponsoredContentTeaserTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SponsoredContentTeaserTargetList` to be created by this mutation. */
  sponsoredContentTeaserTargetList: SponsoredContentTeaserTargetListInput;
};

/** The output of our create `SponsoredContentTeaserTargetList` mutation. */
export type CreateSponsoredContentTeaserTargetListPayload = {
  __typename?: 'CreateSponsoredContentTeaserTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserTargetList` that was created by this mutation. */
  sponsoredContentTeaserTargetList?: Maybe<SponsoredContentTeaserTargetList>;
  /** An edge for our `SponsoredContentTeaserTargetList`. May be used by Relay 1. */
  sponsoredContentTeaserTargetListEdge?: Maybe<SponsoredContentTeaserTargetListsEdge>;
  /** Reads a single `TargetList` that is related to this `SponsoredContentTeaserTargetList`. */
  targetList?: Maybe<TargetList>;
};


/** The output of our create `SponsoredContentTeaserTargetList` mutation. */
export type CreateSponsoredContentTeaserTargetListPayloadSponsoredContentTeaserTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};

/** All input for the create `TargetList` mutation. */
export type CreateTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TargetList` to be created by this mutation. */
  targetList: TargetListInput;
};

/** The output of our create `TargetList` mutation. */
export type CreateTargetListPayload = {
  __typename?: 'CreateTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TargetList` that was created by this mutation. */
  targetList?: Maybe<TargetList>;
  /** An edge for our `TargetList`. May be used by Relay 1. */
  targetListEdge?: Maybe<TargetListsEdge>;
};


/** The output of our create `TargetList` mutation. */
export type CreateTargetListPayloadTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<TargetListsOrderBy>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

export enum DayOfWeekType {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** All input for the `deleteBannerTargeting` mutation. */
export type DeleteBannerTargetingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BannerTargeting` mutation. */
export type DeleteBannerTargetingPayload = {
  __typename?: 'DeleteBannerTargetingPayload';
  /** The `BannerTargeting` that was deleted by this mutation. */
  bannerTargeting?: Maybe<BannerTargeting>;
  /** An edge for our `BannerTargeting`. May be used by Relay 1. */
  bannerTargetingEdge?: Maybe<BannerTargetingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBannerTargetingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TargetList` that is related to this `BannerTargeting`. */
  targetList?: Maybe<TargetList>;
};


/** The output of our delete `BannerTargeting` mutation. */
export type DeleteBannerTargetingPayloadBannerTargetingEdgeArgs = {
  orderBy?: InputMaybe<Array<BannerTargetingsOrderBy>>;
};

/** All input for the `deleteCampaignLabel` mutation. */
export type DeleteCampaignLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  labelId: Scalars['UUID'];
  placementsCampaignId: Scalars['String'];
};

/** The output of our delete `CampaignLabel` mutation. */
export type DeleteCampaignLabelPayload = {
  __typename?: 'DeleteCampaignLabelPayload';
  /** The `CampaignLabel` that was deleted by this mutation. */
  campaignLabel?: Maybe<CampaignLabel>;
  /** An edge for our `CampaignLabel`. May be used by Relay 1. */
  campaignLabelEdge?: Maybe<CampaignLabelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedCampaignLabelNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Label` that is related to this `CampaignLabel`. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `CampaignLabel` mutation. */
export type DeleteCampaignLabelPayloadCampaignLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignLabelsOrderBy>>;
};

/** All input for the `deleteClicksCreative` mutation. */
export type DeleteClicksCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the creative. */
  id: Scalars['Int'];
};

/** The output of our delete `ClicksCreative` mutation. */
export type DeleteClicksCreativePayload = {
  __typename?: 'DeleteClicksCreativePayload';
  /** The `ClicksCreative` that was deleted by this mutation. */
  clicksCreative?: Maybe<ClicksCreative>;
  /** An edge for our `ClicksCreative`. May be used by Relay 1. */
  clicksCreativeEdge?: Maybe<ClicksCreativesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedClicksCreativeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `ClicksCreative` mutation. */
export type DeleteClicksCreativePayloadClicksCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<ClicksCreativesOrderBy>>;
};

/** All input for the `deleteFmaCreative` mutation. */
export type DeleteFmaCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the creative. */
  id: Scalars['Int'];
};

/** The output of our delete `FmaCreative` mutation. */
export type DeleteFmaCreativePayload = {
  __typename?: 'DeleteFmaCreativePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFmaCreativeNodeId?: Maybe<Scalars['ID']>;
  /** The `FmaCreative` that was deleted by this mutation. */
  fmaCreative?: Maybe<FmaCreative>;
  /** An edge for our `FmaCreative`. May be used by Relay 1. */
  fmaCreativeEdge?: Maybe<FmaCreativesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `FmaCreative` mutation. */
export type DeleteFmaCreativePayloadFmaCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<FmaCreativesOrderBy>>;
};

/** All input for the `deleteLabelByName` mutation. */
export type DeleteLabelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteLabel` mutation. */
export type DeleteLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Label` mutation. */
export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLabelNodeId?: Maybe<Scalars['ID']>;
  /** The `Label` that was deleted by this mutation. */
  label?: Maybe<Label>;
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge?: Maybe<LabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Label` mutation. */
export type DeleteLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};

/** All input for the `deleteLineItemLabel` mutation. */
export type DeleteLineItemLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  labelId: Scalars['UUID'];
  placementsLineItemId: Scalars['String'];
};

/** The output of our delete `LineItemLabel` mutation. */
export type DeleteLineItemLabelPayload = {
  __typename?: 'DeleteLineItemLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLineItemLabelNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Label` that is related to this `LineItemLabel`. */
  label?: Maybe<Label>;
  /** The `LineItemLabel` that was deleted by this mutation. */
  lineItemLabel?: Maybe<LineItemLabel>;
  /** An edge for our `LineItemLabel`. May be used by Relay 1. */
  lineItemLabelEdge?: Maybe<LineItemLabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LineItemLabel` mutation. */
export type DeleteLineItemLabelPayloadLineItemLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LineItemLabelsOrderBy>>;
};

/** All input for the `deleteProfession` mutation. */
export type DeleteProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Profession` mutation. */
export type DeleteProfessionPayload = {
  __typename?: 'DeleteProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProfessionNodeId?: Maybe<Scalars['ID']>;
  /** The `Profession` that was deleted by this mutation. */
  profession?: Maybe<Profession>;
  /** An edge for our `Profession`. May be used by Relay 1. */
  professionEdge?: Maybe<ProfessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Profession` mutation. */
export type DeleteProfessionPayloadProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfessionsOrderBy>>;
};

/** All input for the `deleteSpecialty` mutation. */
export type DeleteSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Specialty` mutation. */
export type DeleteSpecialtyPayload = {
  __typename?: 'DeleteSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSpecialtyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Specialty` that was deleted by this mutation. */
  specialty?: Maybe<Specialty>;
  /** An edge for our `Specialty`. May be used by Relay 1. */
  specialtyEdge?: Maybe<SpecialtiesEdge>;
};


/** The output of our delete `Specialty` mutation. */
export type DeleteSpecialtyPayloadSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SpecialtiesOrderBy>>;
};

/** All input for the `deleteSponsoredContentTeaser` mutation. */
export type DeleteSponsoredContentTeaserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `SponsoredContentTeaser` mutation. */
export type DeleteSponsoredContentTeaserPayload = {
  __typename?: 'DeleteSponsoredContentTeaserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSponsoredContentTeaserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SponsoredContentTeaser` that was deleted by this mutation. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** An edge for our `SponsoredContentTeaser`. May be used by Relay 1. */
  sponsoredContentTeaserEdge?: Maybe<SponsoredContentTeasersEdge>;
};


/** The output of our delete `SponsoredContentTeaser` mutation. */
export type DeleteSponsoredContentTeaserPayloadSponsoredContentTeaserEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeasersOrderBy>>;
};

/** All input for the `deleteSponsoredContentTeaserProfession` mutation. */
export type DeleteSponsoredContentTeaserProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  professionId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our delete `SponsoredContentTeaserProfession` mutation. */
export type DeleteSponsoredContentTeaserProfessionPayload = {
  __typename?: 'DeleteSponsoredContentTeaserProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSponsoredContentTeaserProfessionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Profession` that is related to this `SponsoredContentTeaserProfession`. */
  profession?: Maybe<Profession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserProfession` that was deleted by this mutation. */
  sponsoredContentTeaserProfession?: Maybe<SponsoredContentTeaserProfession>;
  /** An edge for our `SponsoredContentTeaserProfession`. May be used by Relay 1. */
  sponsoredContentTeaserProfessionEdge?: Maybe<SponsoredContentTeaserProfessionsEdge>;
};


/** The output of our delete `SponsoredContentTeaserProfession` mutation. */
export type DeleteSponsoredContentTeaserProfessionPayloadSponsoredContentTeaserProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};

/** All input for the `deleteSponsoredContentTeaserSpecialty` mutation. */
export type DeleteSponsoredContentTeaserSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  specialtyId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our delete `SponsoredContentTeaserSpecialty` mutation. */
export type DeleteSponsoredContentTeaserSpecialtyPayload = {
  __typename?: 'DeleteSponsoredContentTeaserSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSponsoredContentTeaserSpecialtyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Specialty` that is related to this `SponsoredContentTeaserSpecialty`. */
  specialty?: Maybe<Specialty>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserSpecialty` that was deleted by this mutation. */
  sponsoredContentTeaserSpecialty?: Maybe<SponsoredContentTeaserSpecialty>;
  /** An edge for our `SponsoredContentTeaserSpecialty`. May be used by Relay 1. */
  sponsoredContentTeaserSpecialtyEdge?: Maybe<SponsoredContentTeaserSpecialtiesEdge>;
};


/** The output of our delete `SponsoredContentTeaserSpecialty` mutation. */
export type DeleteSponsoredContentTeaserSpecialtyPayloadSponsoredContentTeaserSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};

/** All input for the `deleteSponsoredContentTeaserTargetList` mutation. */
export type DeleteSponsoredContentTeaserTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sponsoredContentTeaserId: Scalars['Int'];
  targetListId: Scalars['Int'];
};

/** The output of our delete `SponsoredContentTeaserTargetList` mutation. */
export type DeleteSponsoredContentTeaserTargetListPayload = {
  __typename?: 'DeleteSponsoredContentTeaserTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSponsoredContentTeaserTargetListNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserTargetList` that was deleted by this mutation. */
  sponsoredContentTeaserTargetList?: Maybe<SponsoredContentTeaserTargetList>;
  /** An edge for our `SponsoredContentTeaserTargetList`. May be used by Relay 1. */
  sponsoredContentTeaserTargetListEdge?: Maybe<SponsoredContentTeaserTargetListsEdge>;
  /** Reads a single `TargetList` that is related to this `SponsoredContentTeaserTargetList`. */
  targetList?: Maybe<TargetList>;
};


/** The output of our delete `SponsoredContentTeaserTargetList` mutation. */
export type DeleteSponsoredContentTeaserTargetListPayloadSponsoredContentTeaserTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};

/** All input for the `deleteTargetList` mutation. */
export type DeleteTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the target group. */
  id: Scalars['Int'];
};

/** The output of our delete `TargetList` mutation. */
export type DeleteTargetListPayload = {
  __typename?: 'DeleteTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTargetListNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TargetList` that was deleted by this mutation. */
  targetList?: Maybe<TargetList>;
  /** An edge for our `TargetList`. May be used by Relay 1. */
  targetListEdge?: Maybe<TargetListsEdge>;
};


/** The output of our delete `TargetList` mutation. */
export type DeleteTargetListPayloadTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<TargetListsOrderBy>>;
};

/** All input for the `duplicateClicksCreative` mutation. */
export type DuplicateClicksCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  existingId: Scalars['Int'];
};

/** The output of our `duplicateClicksCreative` mutation. */
export type DuplicateClicksCreativePayload = {
  __typename?: 'DuplicateClicksCreativePayload';
  clicksCreative?: Maybe<ClicksCreative>;
  /** An edge for our `ClicksCreative`. May be used by Relay 1. */
  clicksCreativeEdge?: Maybe<ClicksCreativesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `duplicateClicksCreative` mutation. */
export type DuplicateClicksCreativePayloadClicksCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<ClicksCreativesOrderBy>>;
};

/** All input for the `duplicateFmaCreative` mutation. */
export type DuplicateFmaCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  existingId: Scalars['Int'];
};

/** The output of our `duplicateFmaCreative` mutation. */
export type DuplicateFmaCreativePayload = {
  __typename?: 'DuplicateFmaCreativePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fmaCreative?: Maybe<FmaCreative>;
  /** An edge for our `FmaCreative`. May be used by Relay 1. */
  fmaCreativeEdge?: Maybe<FmaCreativesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `duplicateFmaCreative` mutation. */
export type DuplicateFmaCreativePayloadFmaCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<FmaCreativesOrderBy>>;
};

/** FMA creatives. */
export type FmaCreative = {
  __typename?: 'FmaCreative';
  /** HTML body for the FMA - this is the HTML that is displayed in the modal. */
  bodyHtml: Scalars['String'];
  /** Sponsor/Company/Brand name for the alert. Optional. */
  companyName?: Maybe<Scalars['String']>;
  enableCountdownCloseButton: Scalars['Boolean'];
  /** End date and time when this FMA should stop being displayed. */
  endsAt: Scalars['Datetime'];
  hasActivityData?: Maybe<Scalars['Boolean']>;
  /** ID for the creative. */
  id: Scalars['Int'];
  isApproved?: Maybe<Scalars['Boolean']>;
  /** HTML that is displayed for the ISI of the FMA. */
  isiHtml?: Maybe<Scalars['String']>;
  /** The optional job number ID (a string) that is sometimes displayed with the alert. */
  jobNumber?: Maybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name: Scalars['String'];
  placementsCampaignId?: Maybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId: Scalars['String'];
  priority: Scalars['Int'];
  /** Optional URL to use as destination for "Show Me More Info" button in the alert (aka "Touchpoint 2" URL) */
  showMoreInfoUrl?: Maybe<Scalars['String']>;
  /** Start date and time when this FMA should start to be displayed. */
  startsAt: Scalars['Datetime'];
  targetListIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `TargetList`. */
  targetLists: TargetListsConnection;
  /** Optional title for the alert. May instead be included directly in body HTML */
  title?: Maybe<Scalars['String']>;
  /** Number of views permitted for this FMA to be displayed to a targeted user per week. Defaults to 1. */
  viewsPerWeek: Scalars['Int'];
};


/** FMA creatives. */
export type FmaCreativeTargetListIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** FMA creatives. */
export type FmaCreativeTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `FmaCreative` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FmaCreativeCondition = {
  /** Checks for equality with the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `enableCountdownCloseButton` field. */
  enableCountdownCloseButton?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isiHtml` field. */
  isiHtml?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `jobNumber` field. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `showMoreInfoUrl` field. */
  showMoreInfoUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startsAt` field. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `viewsPerWeek` field. */
  viewsPerWeek?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `FmaCreative` object types. All fields are combined with a logical ‘and.’ */
export type FmaCreativeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FmaCreativeFilter>>;
  /** Filter by the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enableCountdownCloseButton` field. */
  enableCountdownCloseButton?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `hasActivityData` field. */
  hasActivityData?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isiHtml` field. */
  isiHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `jobNumber` field. */
  jobNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FmaCreativeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FmaCreativeFilter>>;
  /** Filter by the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `showMoreInfoUrl` field. */
  showMoreInfoUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startsAt` field. */
  startsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `viewsPerWeek` field. */
  viewsPerWeek?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FmaCreative` */
export type FmaCreativeInput = {
  /** HTML body for the FMA - this is the HTML that is displayed in the modal. */
  bodyHtml: Scalars['String'];
  /** Sponsor/Company/Brand name for the alert. Optional. */
  companyName?: InputMaybe<Scalars['String']>;
  enableCountdownCloseButton?: InputMaybe<Scalars['Boolean']>;
  /** End date and time when this FMA should stop being displayed. */
  endsAt: Scalars['Datetime'];
  /** ID for the creative. */
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** HTML that is displayed for the ISI of the FMA. */
  isiHtml?: InputMaybe<Scalars['String']>;
  /** The optional job number ID (a string) that is sometimes displayed with the alert. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name: Scalars['String'];
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  /** Optional URL to use as destination for "Show Me More Info" button in the alert (aka "Touchpoint 2" URL) */
  showMoreInfoUrl?: InputMaybe<Scalars['String']>;
  /** Start date and time when this FMA should start to be displayed. */
  startsAt: Scalars['Datetime'];
  /** Optional title for the alert. May instead be included directly in body HTML */
  title?: InputMaybe<Scalars['String']>;
  /** Number of views permitted for this FMA to be displayed to a targeted user per week. Defaults to 1. */
  viewsPerWeek?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `FmaCreative`. Fields that are set will be updated. */
export type FmaCreativePatch = {
  /** HTML body for the FMA - this is the HTML that is displayed in the modal. */
  bodyHtml?: InputMaybe<Scalars['String']>;
  /** Sponsor/Company/Brand name for the alert. Optional. */
  companyName?: InputMaybe<Scalars['String']>;
  enableCountdownCloseButton?: InputMaybe<Scalars['Boolean']>;
  /** End date and time when this FMA should stop being displayed. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** ID for the creative. */
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** HTML that is displayed for the ISI of the FMA. */
  isiHtml?: InputMaybe<Scalars['String']>;
  /** The optional job number ID (a string) that is sometimes displayed with the alert. */
  jobNumber?: InputMaybe<Scalars['String']>;
  /** Descriptive name for the creative - "Flight / Segment Name" */
  name?: InputMaybe<Scalars['String']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** ID identifying the Placements Line Item that this creative is for. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  /** Optional URL to use as destination for "Show Me More Info" button in the alert (aka "Touchpoint 2" URL) */
  showMoreInfoUrl?: InputMaybe<Scalars['String']>;
  /** Start date and time when this FMA should start to be displayed. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Optional title for the alert. May instead be included directly in body HTML */
  title?: InputMaybe<Scalars['String']>;
  /** Number of views permitted for this FMA to be displayed to a targeted user per week. Defaults to 1. */
  viewsPerWeek?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `FmaCreative` values. */
export type FmaCreativesConnection = {
  __typename?: 'FmaCreativesConnection';
  /** A list of edges which contains the `FmaCreative` and cursor to aid in pagination. */
  edges: Array<FmaCreativesEdge>;
  /** A list of `FmaCreative` objects. */
  nodes: Array<FmaCreative>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FmaCreative` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FmaCreative` edge in the connection. */
export type FmaCreativesEdge = {
  __typename?: 'FmaCreativesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FmaCreative` at the end of the edge. */
  node: FmaCreative;
};

/** Methods to use when ordering `FmaCreative`. */
export enum FmaCreativesOrderBy {
  BodyHtmlAsc = 'BODY_HTML_ASC',
  BodyHtmlDesc = 'BODY_HTML_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  EnableCountdownCloseButtonAsc = 'ENABLE_COUNTDOWN_CLOSE_BUTTON_ASC',
  EnableCountdownCloseButtonDesc = 'ENABLE_COUNTDOWN_CLOSE_BUTTON_DESC',
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsiHtmlAsc = 'ISI_HTML_ASC',
  IsiHtmlDesc = 'ISI_HTML_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  JobNumberAsc = 'JOB_NUMBER_ASC',
  JobNumberDesc = 'JOB_NUMBER_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PlacementsCampaignIdAsc = 'PLACEMENTS_CAMPAIGN_ID_ASC',
  PlacementsCampaignIdDesc = 'PLACEMENTS_CAMPAIGN_ID_DESC',
  PlacementsLineItemIdAsc = 'PLACEMENTS_LINE_ITEM_ID_ASC',
  PlacementsLineItemIdDesc = 'PLACEMENTS_LINE_ITEM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ShowMoreInfoUrlAsc = 'SHOW_MORE_INFO_URL_ASC',
  ShowMoreInfoUrlDesc = 'SHOW_MORE_INFO_URL_DESC',
  StartsAtAsc = 'STARTS_AT_ASC',
  StartsAtDesc = 'STARTS_AT_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  ViewsPerWeekAsc = 'VIEWS_PER_WEEK_ASC',
  ViewsPerWeekDesc = 'VIEWS_PER_WEEK_DESC'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** Table for storing unique labels to implement the Competitive Separation feature. */
export type Label = {
  __typename?: 'Label';
  /** Reads and enables pagination through a set of `CampaignLabel`. */
  campaignLabels: CampaignLabelsConnection;
  createdAt: Scalars['Datetime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `LineItemLabel`. */
  lineItemLabels: LineItemLabelsConnection;
  name: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  updatedBy?: Maybe<Scalars['String']>;
};


/** Table for storing unique labels to implement the Competitive Separation feature. */
export type LabelCampaignLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignLabelCondition>;
  filter?: InputMaybe<CampaignLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignLabelsOrderBy>>;
};


/** Table for storing unique labels to implement the Competitive Separation feature. */
export type LabelLineItemLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LineItemLabelCondition>;
  filter?: InputMaybe<LineItemLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineItemLabelsOrderBy>>;
};

/** A condition to be used against `Label` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LabelCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Label` object types. All fields are combined with a logical ‘and.’ */
export type LabelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LabelFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LabelFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Label` */
export type LabelInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `Label`. Fields that are set will be updated. */
export type LabelPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Label` values. */
export type LabelsConnection = {
  __typename?: 'LabelsConnection';
  /** A list of edges which contains the `Label` and cursor to aid in pagination. */
  edges: Array<LabelsEdge>;
  /** A list of `Label` objects. */
  nodes: Array<Label>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Label` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Label` edge in the connection. */
export type LabelsEdge = {
  __typename?: 'LabelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Label` at the end of the edge. */
  node: Label;
};

/** Methods to use when ordering `Label`. */
export enum LabelsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** Table for managing the relationship between line items and labels. */
export type LineItemLabel = {
  __typename?: 'LineItemLabel';
  /** Reads a single `Label` that is related to this `LineItemLabel`. */
  label?: Maybe<Label>;
  labelId: Scalars['UUID'];
  placementsLineItemId: Scalars['String'];
};

/**
 * A condition to be used against `LineItemLabel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LineItemLabelCondition = {
  /** Checks for equality with the object’s `labelId` field. */
  labelId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `LineItemLabel` object types. All fields are combined with a logical ‘and.’ */
export type LineItemLabelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LineItemLabelFilter>>;
  /** Filter by the object’s `labelId` field. */
  labelId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LineItemLabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LineItemLabelFilter>>;
  /** Filter by the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `LineItemLabel` */
export type LineItemLabelInput = {
  labelId: Scalars['UUID'];
  placementsLineItemId: Scalars['String'];
};

/** Represents an update to a `LineItemLabel`. Fields that are set will be updated. */
export type LineItemLabelPatch = {
  labelId?: InputMaybe<Scalars['UUID']>;
  placementsLineItemId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `LineItemLabel` values. */
export type LineItemLabelsConnection = {
  __typename?: 'LineItemLabelsConnection';
  /** A list of edges which contains the `LineItemLabel` and cursor to aid in pagination. */
  edges: Array<LineItemLabelsEdge>;
  /** A list of `LineItemLabel` objects. */
  nodes: Array<LineItemLabel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LineItemLabel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LineItemLabel` edge in the connection. */
export type LineItemLabelsEdge = {
  __typename?: 'LineItemLabelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LineItemLabel` at the end of the edge. */
  node: LineItemLabel;
};

/** Methods to use when ordering `LineItemLabel`. */
export enum LineItemLabelsOrderBy {
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  Natural = 'NATURAL',
  PlacementsLineItemIdAsc = 'PLACEMENTS_LINE_ITEM_ID_ASC',
  PlacementsLineItemIdDesc = 'PLACEMENTS_LINE_ITEM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `BannerTargeting`. */
  createBannerTargeting?: Maybe<CreateBannerTargetingPayload>;
  /** Creates a single `CampaignLabel`. */
  createCampaignLabel?: Maybe<CreateCampaignLabelPayload>;
  /** Creates a single `ClicksCreative`. */
  createClicksCreative?: Maybe<CreateClicksCreativePayload>;
  /** Creates a single `FmaCreative`. */
  createFmaCreative?: Maybe<CreateFmaCreativePayload>;
  /** Creates a single `Label`. */
  createLabel?: Maybe<CreateLabelPayload>;
  /** Creates a single `LineItemLabel`. */
  createLineItemLabel?: Maybe<CreateLineItemLabelPayload>;
  /** Creates a single `Profession`. */
  createProfession?: Maybe<CreateProfessionPayload>;
  /** Creates a single `Specialty`. */
  createSpecialty?: Maybe<CreateSpecialtyPayload>;
  /** Creates a single `SponsoredContentTeaser`. */
  createSponsoredContentTeaser?: Maybe<CreateSponsoredContentTeaserPayload>;
  /** Creates a single `SponsoredContentTeaserProfession`. */
  createSponsoredContentTeaserProfession?: Maybe<CreateSponsoredContentTeaserProfessionPayload>;
  /** Creates a single `SponsoredContentTeaserSpecialty`. */
  createSponsoredContentTeaserSpecialty?: Maybe<CreateSponsoredContentTeaserSpecialtyPayload>;
  /** Creates a single `SponsoredContentTeaserTargetList`. */
  createSponsoredContentTeaserTargetList?: Maybe<CreateSponsoredContentTeaserTargetListPayload>;
  /** Creates a single `TargetList`. */
  createTargetList?: Maybe<CreateTargetListPayload>;
  /** Deletes a single `BannerTargeting` using a unique key. */
  deleteBannerTargeting?: Maybe<DeleteBannerTargetingPayload>;
  /** Deletes a single `CampaignLabel` using a unique key. */
  deleteCampaignLabel?: Maybe<DeleteCampaignLabelPayload>;
  /** Deletes a single `ClicksCreative` using a unique key. */
  deleteClicksCreative?: Maybe<DeleteClicksCreativePayload>;
  /** Deletes a single `FmaCreative` using a unique key. */
  deleteFmaCreative?: Maybe<DeleteFmaCreativePayload>;
  /** Deletes a single `Label` using a unique key. */
  deleteLabel?: Maybe<DeleteLabelPayload>;
  /** Deletes a single `Label` using a unique key. */
  deleteLabelByName?: Maybe<DeleteLabelPayload>;
  /** Deletes a single `LineItemLabel` using a unique key. */
  deleteLineItemLabel?: Maybe<DeleteLineItemLabelPayload>;
  /** Deletes a single `Profession` using a unique key. */
  deleteProfession?: Maybe<DeleteProfessionPayload>;
  /** Deletes a single `Specialty` using a unique key. */
  deleteSpecialty?: Maybe<DeleteSpecialtyPayload>;
  /** Deletes a single `SponsoredContentTeaser` using a unique key. */
  deleteSponsoredContentTeaser?: Maybe<DeleteSponsoredContentTeaserPayload>;
  /** Deletes a single `SponsoredContentTeaserProfession` using a unique key. */
  deleteSponsoredContentTeaserProfession?: Maybe<DeleteSponsoredContentTeaserProfessionPayload>;
  /** Deletes a single `SponsoredContentTeaserSpecialty` using a unique key. */
  deleteSponsoredContentTeaserSpecialty?: Maybe<DeleteSponsoredContentTeaserSpecialtyPayload>;
  /** Deletes a single `SponsoredContentTeaserTargetList` using a unique key. */
  deleteSponsoredContentTeaserTargetList?: Maybe<DeleteSponsoredContentTeaserTargetListPayload>;
  /** Deletes a single `TargetList` using a unique key. */
  deleteTargetList?: Maybe<DeleteTargetListPayload>;
  duplicateClicksCreative?: Maybe<DuplicateClicksCreativePayload>;
  duplicateFmaCreative?: Maybe<DuplicateFmaCreativePayload>;
  /** Set the days to hide the creative to the specified list of days. Idempotent, avoids updating timestamps for unchanged records. */
  setClicksCreativeHideOnDays?: Maybe<SetClicksCreativeHideOnDaysPayload>;
  /** Set the professions for the specified Clicks creative to the specified list of profession ids. Idempotent, avoids updating timestamps for unchanged records. */
  setClicksCreativeProfessions?: Maybe<SetClicksCreativeProfessionsPayload>;
  /** Set the specialties for the specified Clicks creative to the specified list of specialty ids. Idempotent, avoids updating timestamps for unchanged records. */
  setClicksCreativeSpecialties?: Maybe<SetClicksCreativeSpecialtiesPayload>;
  setClicksCreativeTargetLists?: Maybe<SetClicksCreativeTargetListsPayload>;
  setFmaCreativeTargetLists?: Maybe<SetFmaCreativeTargetListsPayload>;
  /** Set the professions for the specified Sponsored Content Teaser to the specified list of profession ids. Idempotent, avoids updating timestamps for unchanged records. */
  setSponsoredContentTeaserProfessions?: Maybe<SetSponsoredContentTeaserProfessionsPayload>;
  /** Set the specialties for the specified Sponsored Content Teaser to the specified list of specialty ids. Idempotent, avoids updating timestamps for unchanged records. */
  setSponsoredContentTeaserSpecialties?: Maybe<SetSponsoredContentTeaserSpecialtiesPayload>;
  /** Set the target lists for the specified Sponsored Content Teaser to the specified list of target list ids. Idempotent, avoids updating timestamps for unchanged records. */
  setSponsoredContentTeaserTargetLists?: Maybe<SetSponsoredContentTeaserTargetListsPayload>;
  setTargetListLineItems?: Maybe<SetTargetListLineItemsPayload>;
  /** Set the NPIs for the specified target group to the specified list of NPIs. Idempotent, avoids updating timestamps for unchanged records. */
  setTargetListNpis?: Maybe<SetTargetListNpisPayload>;
  /** Updates a single `CampaignLabel` using a unique key and a patch. */
  updateCampaignLabel?: Maybe<UpdateCampaignLabelPayload>;
  /** Updates a single `ClicksCreative` using a unique key and a patch. */
  updateClicksCreative?: Maybe<UpdateClicksCreativePayload>;
  /** Updates a single `FmaCreative` using a unique key and a patch. */
  updateFmaCreative?: Maybe<UpdateFmaCreativePayload>;
  /** Updates a single `Label` using a unique key and a patch. */
  updateLabel?: Maybe<UpdateLabelPayload>;
  /** Updates a single `Label` using a unique key and a patch. */
  updateLabelByName?: Maybe<UpdateLabelPayload>;
  /** Updates a single `LineItemLabel` using a unique key and a patch. */
  updateLineItemLabel?: Maybe<UpdateLineItemLabelPayload>;
  /** Updates a single `Profession` using a unique key and a patch. */
  updateProfession?: Maybe<UpdateProfessionPayload>;
  /** Updates a single `Specialty` using a unique key and a patch. */
  updateSpecialty?: Maybe<UpdateSpecialtyPayload>;
  /** Updates a single `SponsoredContentTeaser` using a unique key and a patch. */
  updateSponsoredContentTeaser?: Maybe<UpdateSponsoredContentTeaserPayload>;
  /** Updates a single `SponsoredContentTeaserProfession` using a unique key and a patch. */
  updateSponsoredContentTeaserProfession?: Maybe<UpdateSponsoredContentTeaserProfessionPayload>;
  /** Updates a single `SponsoredContentTeaserSpecialty` using a unique key and a patch. */
  updateSponsoredContentTeaserSpecialty?: Maybe<UpdateSponsoredContentTeaserSpecialtyPayload>;
  /** Updates a single `SponsoredContentTeaserTargetList` using a unique key and a patch. */
  updateSponsoredContentTeaserTargetList?: Maybe<UpdateSponsoredContentTeaserTargetListPayload>;
  /** Updates a single `TargetList` using a unique key and a patch. */
  updateTargetList?: Maybe<UpdateTargetListPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBannerTargetingArgs = {
  input: CreateBannerTargetingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignLabelArgs = {
  input: CreateCampaignLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClicksCreativeArgs = {
  input: CreateClicksCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFmaCreativeArgs = {
  input: CreateFmaCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLineItemLabelArgs = {
  input: CreateLineItemLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProfessionArgs = {
  input: CreateProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSpecialtyArgs = {
  input: CreateSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSponsoredContentTeaserArgs = {
  input: CreateSponsoredContentTeaserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSponsoredContentTeaserProfessionArgs = {
  input: CreateSponsoredContentTeaserProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSponsoredContentTeaserSpecialtyArgs = {
  input: CreateSponsoredContentTeaserSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSponsoredContentTeaserTargetListArgs = {
  input: CreateSponsoredContentTeaserTargetListInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTargetListArgs = {
  input: CreateTargetListInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBannerTargetingArgs = {
  input: DeleteBannerTargetingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignLabelArgs = {
  input: DeleteCampaignLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClicksCreativeArgs = {
  input: DeleteClicksCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFmaCreativeArgs = {
  input: DeleteFmaCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelByNameArgs = {
  input: DeleteLabelByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLineItemLabelArgs = {
  input: DeleteLineItemLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfessionArgs = {
  input: DeleteProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSpecialtyArgs = {
  input: DeleteSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsoredContentTeaserArgs = {
  input: DeleteSponsoredContentTeaserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsoredContentTeaserProfessionArgs = {
  input: DeleteSponsoredContentTeaserProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsoredContentTeaserSpecialtyArgs = {
  input: DeleteSponsoredContentTeaserSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsoredContentTeaserTargetListArgs = {
  input: DeleteSponsoredContentTeaserTargetListInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTargetListArgs = {
  input: DeleteTargetListInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateClicksCreativeArgs = {
  input: DuplicateClicksCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateFmaCreativeArgs = {
  input: DuplicateFmaCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetClicksCreativeHideOnDaysArgs = {
  input: SetClicksCreativeHideOnDaysInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetClicksCreativeProfessionsArgs = {
  input: SetClicksCreativeProfessionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetClicksCreativeSpecialtiesArgs = {
  input: SetClicksCreativeSpecialtiesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetClicksCreativeTargetListsArgs = {
  input: SetClicksCreativeTargetListsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetFmaCreativeTargetListsArgs = {
  input: SetFmaCreativeTargetListsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetSponsoredContentTeaserProfessionsArgs = {
  input: SetSponsoredContentTeaserProfessionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetSponsoredContentTeaserSpecialtiesArgs = {
  input: SetSponsoredContentTeaserSpecialtiesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetSponsoredContentTeaserTargetListsArgs = {
  input: SetSponsoredContentTeaserTargetListsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetTargetListLineItemsArgs = {
  input: SetTargetListLineItemsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetTargetListNpisArgs = {
  input: SetTargetListNpisInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignLabelArgs = {
  input: UpdateCampaignLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClicksCreativeArgs = {
  input: UpdateClicksCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFmaCreativeArgs = {
  input: UpdateFmaCreativeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelByNameArgs = {
  input: UpdateLabelByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLineItemLabelArgs = {
  input: UpdateLineItemLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfessionArgs = {
  input: UpdateProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSpecialtyArgs = {
  input: UpdateSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsoredContentTeaserArgs = {
  input: UpdateSponsoredContentTeaserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsoredContentTeaserProfessionArgs = {
  input: UpdateSponsoredContentTeaserProfessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsoredContentTeaserSpecialtyArgs = {
  input: UpdateSponsoredContentTeaserSpecialtyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsoredContentTeaserTargetListArgs = {
  input: UpdateSponsoredContentTeaserTargetListInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTargetListArgs = {
  input: UpdateTargetListInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

/** Professions that can be used for targeting. */
export type Profession = {
  __typename?: 'Profession';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  legacyProfessionId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** Reads and enables pagination through a set of `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaserProfessions: SponsoredContentTeaserProfessionsConnection;
  updatedAt: Scalars['Datetime'];
};


/** Professions that can be used for targeting. */
export type ProfessionSponsoredContentTeaserProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserProfessionCondition>;
  filter?: InputMaybe<SponsoredContentTeaserProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};

/**
 * A condition to be used against `Profession` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProfessionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `legacyProfessionId` field. */
  legacyProfessionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Profession` object types. All fields are combined with a logical ‘and.’ */
export type ProfessionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfessionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `legacyProfessionId` field. */
  legacyProfessionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfessionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfessionFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Profession` */
export type ProfessionInput = {
  legacyProfessionId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** Represents an update to a `Profession`. Fields that are set will be updated. */
export type ProfessionPatch = {
  legacyProfessionId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Profession` values. */
export type ProfessionsConnection = {
  __typename?: 'ProfessionsConnection';
  /** A list of edges which contains the `Profession` and cursor to aid in pagination. */
  edges: Array<ProfessionsEdge>;
  /** A list of `Profession` objects. */
  nodes: Array<Profession>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Profession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Profession` edge in the connection. */
export type ProfessionsEdge = {
  __typename?: 'ProfessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Profession` at the end of the edge. */
  node: Profession;
};

/** Methods to use when ordering `Profession`. */
export enum ProfessionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LegacyProfessionIdAsc = 'LEGACY_PROFESSION_ID_ASC',
  LegacyProfessionIdDesc = 'LEGACY_PROFESSION_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = {
  __typename?: 'Query';
  bannerTargeting?: Maybe<BannerTargeting>;
  /** Reads and enables pagination through a set of `BannerTargeting`. */
  bannerTargetings?: Maybe<BannerTargetingsConnection>;
  campaignLabel?: Maybe<CampaignLabel>;
  /** Reads and enables pagination through a set of `CampaignLabel`. */
  campaignLabels?: Maybe<CampaignLabelsConnection>;
  clicksCreative?: Maybe<ClicksCreative>;
  /** Reads and enables pagination through a set of `ClicksCreative`. */
  clicksCreatives?: Maybe<ClicksCreativesConnection>;
  fmaCreative?: Maybe<FmaCreative>;
  /** Reads and enables pagination through a set of `FmaCreative`. */
  fmaCreatives?: Maybe<FmaCreativesConnection>;
  label?: Maybe<Label>;
  labelByName?: Maybe<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labels?: Maybe<LabelsConnection>;
  lineItemLabel?: Maybe<LineItemLabel>;
  /** Reads and enables pagination through a set of `LineItemLabel`. */
  lineItemLabels?: Maybe<LineItemLabelsConnection>;
  profession?: Maybe<Profession>;
  /** Reads and enables pagination through a set of `Profession`. */
  professions?: Maybe<ProfessionsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `Specialty`. */
  specialties?: Maybe<SpecialtiesConnection>;
  specialty?: Maybe<Specialty>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaser`. */
  sponsoredContentIdsForUser?: Maybe<SponsoredContentTeasersConnection>;
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  sponsoredContentTeaserProfession?: Maybe<SponsoredContentTeaserProfession>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaserProfessions?: Maybe<SponsoredContentTeaserProfessionsConnection>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaserSpecialties?: Maybe<SponsoredContentTeaserSpecialtiesConnection>;
  sponsoredContentTeaserSpecialty?: Maybe<SponsoredContentTeaserSpecialty>;
  sponsoredContentTeaserTargetList?: Maybe<SponsoredContentTeaserTargetList>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaserTargetLists?: Maybe<SponsoredContentTeaserTargetListsConnection>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaser`. */
  sponsoredContentTeasers?: Maybe<SponsoredContentTeasersConnection>;
  targetList?: Maybe<TargetList>;
  /** Reads and enables pagination through a set of `TargetList`. */
  targetLists?: Maybe<TargetListsConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBannerTargetingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBannerTargetingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BannerTargetingCondition>;
  filter?: InputMaybe<BannerTargetingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BannerTargetingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignLabelArgs = {
  labelId: Scalars['UUID'];
  placementsCampaignId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignLabelCondition>;
  filter?: InputMaybe<CampaignLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignLabelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClicksCreativeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClicksCreativesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClicksCreativeCondition>;
  filter?: InputMaybe<ClicksCreativeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClicksCreativesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFmaCreativeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFmaCreativesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FmaCreativeCondition>;
  filter?: InputMaybe<FmaCreativeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FmaCreativesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LabelCondition>;
  filter?: InputMaybe<LabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLineItemLabelArgs = {
  labelId: Scalars['UUID'];
  placementsLineItemId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLineItemLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LineItemLabelCondition>;
  filter?: InputMaybe<LineItemLabelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineItemLabelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProfessionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProfessionCondition>;
  filter?: InputMaybe<ProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProfessionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SpecialtyCondition>;
  filter?: InputMaybe<SpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SpecialtiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySpecialtyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentIdsForUserArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SponsoredContentTeaserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  legacyProfessionId?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  specialtySlug?: InputMaybe<Scalars['String']>;
  userNpi?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserProfessionArgs = {
  professionId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserProfessionCondition>;
  filter?: InputMaybe<SponsoredContentTeaserProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserSpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserSpecialtyCondition>;
  filter?: InputMaybe<SponsoredContentTeaserSpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserSpecialtyArgs = {
  specialtyId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserTargetListArgs = {
  sponsoredContentTeaserId: Scalars['Int'];
  targetListId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeaserTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserTargetListCondition>;
  filter?: InputMaybe<SponsoredContentTeaserTargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsoredContentTeasersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserCondition>;
  filter?: InputMaybe<SponsoredContentTeaserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeasersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTargetListArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TargetListCondition>;
  filter?: InputMaybe<TargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TargetListsOrderBy>>;
};

/** All input for the `setClicksCreativeHideOnDays` mutation. */
export type SetClicksCreativeHideOnDaysInput = {
  clicksCreativeId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  daysToHide: Array<InputMaybe<DayOfWeekType>>;
};

/** The output of our `setClicksCreativeHideOnDays` mutation. */
export type SetClicksCreativeHideOnDaysPayload = {
  __typename?: 'SetClicksCreativeHideOnDaysPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setClicksCreativeProfessions` mutation. */
export type SetClicksCreativeProfessionsInput = {
  clicksCreativeId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  professionIds: Array<InputMaybe<Scalars['Int']>>;
};

/** The output of our `setClicksCreativeProfessions` mutation. */
export type SetClicksCreativeProfessionsPayload = {
  __typename?: 'SetClicksCreativeProfessionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setClicksCreativeSpecialties` mutation. */
export type SetClicksCreativeSpecialtiesInput = {
  clicksCreativeId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>>;
};

/** The output of our `setClicksCreativeSpecialties` mutation. */
export type SetClicksCreativeSpecialtiesPayload = {
  __typename?: 'SetClicksCreativeSpecialtiesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setClicksCreativeTargetLists` mutation. */
export type SetClicksCreativeTargetListsInput = {
  clicksCreativeId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetListIds: Array<InputMaybe<Scalars['Int']>>;
};

/** The output of our `setClicksCreativeTargetLists` mutation. */
export type SetClicksCreativeTargetListsPayload = {
  __typename?: 'SetClicksCreativeTargetListsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setFmaCreativeTargetLists` mutation. */
export type SetFmaCreativeTargetListsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fmaCreativeId: Scalars['Int'];
  targetListIds: Array<InputMaybe<Scalars['Int']>>;
};

/** The output of our `setFmaCreativeTargetLists` mutation. */
export type SetFmaCreativeTargetListsPayload = {
  __typename?: 'SetFmaCreativeTargetListsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setSponsoredContentTeaserProfessions` mutation. */
export type SetSponsoredContentTeaserProfessionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  professionIds: Array<InputMaybe<Scalars['Int']>>;
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our `setSponsoredContentTeaserProfessions` mutation. */
export type SetSponsoredContentTeaserProfessionsPayload = {
  __typename?: 'SetSponsoredContentTeaserProfessionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setSponsoredContentTeaserSpecialties` mutation. */
export type SetSponsoredContentTeaserSpecialtiesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>>;
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our `setSponsoredContentTeaserSpecialties` mutation. */
export type SetSponsoredContentTeaserSpecialtiesPayload = {
  __typename?: 'SetSponsoredContentTeaserSpecialtiesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setSponsoredContentTeaserTargetLists` mutation. */
export type SetSponsoredContentTeaserTargetListsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sponsoredContentTeaserId: Scalars['Int'];
  targetListIds: Array<InputMaybe<Scalars['Int']>>;
};

/** The output of our `setSponsoredContentTeaserTargetLists` mutation. */
export type SetSponsoredContentTeaserTargetListsPayload = {
  __typename?: 'SetSponsoredContentTeaserTargetListsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setTargetListLineItems` mutation. */
export type SetTargetListLineItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lineItemIds: Array<InputMaybe<Scalars['String']>>;
  targetListId: Scalars['Int'];
};

/** The output of our `setTargetListLineItems` mutation. */
export type SetTargetListLineItemsPayload = {
  __typename?: 'SetTargetListLineItemsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setTargetListNpis` mutation. */
export type SetTargetListNpisInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  npis: Array<InputMaybe<Scalars['Int']>>;
  targetListId: Scalars['Int'];
};

/** The output of our `setTargetListNpis` mutation. */
export type SetTargetListNpisPayload = {
  __typename?: 'SetTargetListNpisPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `Specialty` values. */
export type SpecialtiesConnection = {
  __typename?: 'SpecialtiesConnection';
  /** A list of edges which contains the `Specialty` and cursor to aid in pagination. */
  edges: Array<SpecialtiesEdge>;
  /** A list of `Specialty` objects. */
  nodes: Array<Specialty>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Specialty` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Specialty` edge in the connection. */
export type SpecialtiesEdge = {
  __typename?: 'SpecialtiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Specialty` at the end of the edge. */
  node: Specialty;
};

/** Methods to use when ordering `Specialty`. */
export enum SpecialtiesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Specialties that can be used for targeting, based on page specialty tag or user specialty. */
export type Specialty = {
  __typename?: 'Specialty';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  /** Reads and enables pagination through a set of `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaserSpecialties: SponsoredContentTeaserSpecialtiesConnection;
  updatedAt: Scalars['Datetime'];
};


/** Specialties that can be used for targeting, based on page specialty tag or user specialty. */
export type SpecialtySponsoredContentTeaserSpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserSpecialtyCondition>;
  filter?: InputMaybe<SponsoredContentTeaserSpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};

/**
 * A condition to be used against `Specialty` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SpecialtyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Specialty` object types. All fields are combined with a logical ‘and.’ */
export type SpecialtyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SpecialtyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SpecialtyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SpecialtyFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Specialty` */
export type SpecialtyInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** Represents an update to a `Specialty`. Fields that are set will be updated. */
export type SpecialtyPatch = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type SponsoredContentTeaser = {
  __typename?: 'SponsoredContentTeaser';
  /** Sponsor/Company/Brand name for the Sponsored Content Teaser. */
  companyName: Scalars['String'];
  contentId: Scalars['String'];
  createdAt: Scalars['Datetime'];
  endsAt: Scalars['Datetime'];
  id: Scalars['Int'];
  isApproved?: Maybe<Scalars['Boolean']>;
  placementsCampaignId?: Maybe<Scalars['String']>;
  placementsLineItemId: Scalars['String'];
  priority: Scalars['Int'];
  professionIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Get the professions associated with this sponsored content teaser. */
  professions: ProfessionsConnection;
  /** Get the specialties associated with this sponsored content teaser. */
  specialties: SpecialtiesConnection;
  specialtyIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaserProfessions: SponsoredContentTeaserProfessionsConnection;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaserSpecialties: SponsoredContentTeaserSpecialtiesConnection;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaserTargetLists: SponsoredContentTeaserTargetListsConnection;
  startsAt: Scalars['Datetime'];
  targetListIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Get the target lists associated with this sponsored content teaser. */
  targetLists: TargetListsConnection;
  targetingType?: Maybe<ClicksCreativeTargetingType>;
  title: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};


export type SponsoredContentTeaserProfessionIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SponsoredContentTeaserProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SponsoredContentTeaserSpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SponsoredContentTeaserSpecialtyIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SponsoredContentTeaserSponsoredContentTeaserProfessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserProfessionCondition>;
  filter?: InputMaybe<SponsoredContentTeaserProfessionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};


export type SponsoredContentTeaserSponsoredContentTeaserSpecialtiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserSpecialtyCondition>;
  filter?: InputMaybe<SponsoredContentTeaserSpecialtyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};


export type SponsoredContentTeaserSponsoredContentTeaserTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserTargetListCondition>;
  filter?: InputMaybe<SponsoredContentTeaserTargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};


export type SponsoredContentTeaserTargetListIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SponsoredContentTeaserTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `SponsoredContentTeaser` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SponsoredContentTeaserCondition = {
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `contentId` field. */
  contentId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startsAt` field. */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `targetingType` field. */
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `SponsoredContentTeaser` object types. All fields are combined with a logical ‘and.’ */
export type SponsoredContentTeaserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SponsoredContentTeaserFilter>>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contentId` field. */
  contentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SponsoredContentTeaserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SponsoredContentTeaserFilter>>;
  /** Filter by the object’s `placementsCampaignId` field. */
  placementsCampaignId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placementsLineItemId` field. */
  placementsLineItemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `startsAt` field. */
  startsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetingType` field. */
  targetingType?: InputMaybe<ClicksCreativeTargetingTypeFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `SponsoredContentTeaser` */
export type SponsoredContentTeaserInput = {
  /** Sponsor/Company/Brand name for the Sponsored Content Teaser. */
  companyName: Scalars['String'];
  contentId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  endsAt: Scalars['Datetime'];
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  placementsLineItemId: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  startsAt: Scalars['Datetime'];
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `SponsoredContentTeaser`. Fields that are set will be updated. */
export type SponsoredContentTeaserPatch = {
  /** Sponsor/Company/Brand name for the Sponsored Content Teaser. */
  companyName?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  endsAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  placementsCampaignId?: InputMaybe<Scalars['String']>;
  placementsLineItemId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['Datetime']>;
  targetingType?: InputMaybe<ClicksCreativeTargetingType>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type SponsoredContentTeaserProfession = {
  __typename?: 'SponsoredContentTeaserProfession';
  createdAt: Scalars['Datetime'];
  /** Reads a single `Profession` that is related to this `SponsoredContentTeaserProfession`. */
  profession?: Maybe<Profession>;
  professionId: Scalars['Int'];
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  sponsoredContentTeaserId: Scalars['Int'];
};

/**
 * A condition to be used against `SponsoredContentTeaserProfession` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SponsoredContentTeaserProfessionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `professionId` field. */
  professionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `SponsoredContentTeaserProfession` object types. All fields are combined with a logical ‘and.’ */
export type SponsoredContentTeaserProfessionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SponsoredContentTeaserProfessionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SponsoredContentTeaserProfessionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SponsoredContentTeaserProfessionFilter>>;
  /** Filter by the object’s `professionId` field. */
  professionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SponsoredContentTeaserProfession` */
export type SponsoredContentTeaserProfessionInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  professionId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** Represents an update to a `SponsoredContentTeaserProfession`. Fields that are set will be updated. */
export type SponsoredContentTeaserProfessionPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  professionId?: InputMaybe<Scalars['Int']>;
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `SponsoredContentTeaserProfession` values. */
export type SponsoredContentTeaserProfessionsConnection = {
  __typename?: 'SponsoredContentTeaserProfessionsConnection';
  /** A list of edges which contains the `SponsoredContentTeaserProfession` and cursor to aid in pagination. */
  edges: Array<SponsoredContentTeaserProfessionsEdge>;
  /** A list of `SponsoredContentTeaserProfession` objects. */
  nodes: Array<SponsoredContentTeaserProfession>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SponsoredContentTeaserProfession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SponsoredContentTeaserProfession` edge in the connection. */
export type SponsoredContentTeaserProfessionsEdge = {
  __typename?: 'SponsoredContentTeaserProfessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SponsoredContentTeaserProfession` at the end of the edge. */
  node: SponsoredContentTeaserProfession;
};

/** Methods to use when ordering `SponsoredContentTeaserProfession`. */
export enum SponsoredContentTeaserProfessionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfessionIdAsc = 'PROFESSION_ID_ASC',
  ProfessionIdDesc = 'PROFESSION_ID_DESC',
  SponsoredContentTeaserIdAsc = 'SPONSORED_CONTENT_TEASER_ID_ASC',
  SponsoredContentTeaserIdDesc = 'SPONSORED_CONTENT_TEASER_ID_DESC'
}

/** A connection to a list of `SponsoredContentTeaserSpecialty` values. */
export type SponsoredContentTeaserSpecialtiesConnection = {
  __typename?: 'SponsoredContentTeaserSpecialtiesConnection';
  /** A list of edges which contains the `SponsoredContentTeaserSpecialty` and cursor to aid in pagination. */
  edges: Array<SponsoredContentTeaserSpecialtiesEdge>;
  /** A list of `SponsoredContentTeaserSpecialty` objects. */
  nodes: Array<SponsoredContentTeaserSpecialty>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SponsoredContentTeaserSpecialty` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SponsoredContentTeaserSpecialty` edge in the connection. */
export type SponsoredContentTeaserSpecialtiesEdge = {
  __typename?: 'SponsoredContentTeaserSpecialtiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SponsoredContentTeaserSpecialty` at the end of the edge. */
  node: SponsoredContentTeaserSpecialty;
};

/** Methods to use when ordering `SponsoredContentTeaserSpecialty`. */
export enum SponsoredContentTeaserSpecialtiesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SpecialtyIdAsc = 'SPECIALTY_ID_ASC',
  SpecialtyIdDesc = 'SPECIALTY_ID_DESC',
  SponsoredContentTeaserIdAsc = 'SPONSORED_CONTENT_TEASER_ID_ASC',
  SponsoredContentTeaserIdDesc = 'SPONSORED_CONTENT_TEASER_ID_DESC'
}

export type SponsoredContentTeaserSpecialty = {
  __typename?: 'SponsoredContentTeaserSpecialty';
  createdAt: Scalars['Datetime'];
  /** Reads a single `Specialty` that is related to this `SponsoredContentTeaserSpecialty`. */
  specialty?: Maybe<Specialty>;
  specialtyId: Scalars['Int'];
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  sponsoredContentTeaserId: Scalars['Int'];
};

/**
 * A condition to be used against `SponsoredContentTeaserSpecialty` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SponsoredContentTeaserSpecialtyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `specialtyId` field. */
  specialtyId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `SponsoredContentTeaserSpecialty` object types. All fields are combined with a logical ‘and.’ */
export type SponsoredContentTeaserSpecialtyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SponsoredContentTeaserSpecialtyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SponsoredContentTeaserSpecialtyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SponsoredContentTeaserSpecialtyFilter>>;
  /** Filter by the object’s `specialtyId` field. */
  specialtyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SponsoredContentTeaserSpecialty` */
export type SponsoredContentTeaserSpecialtyInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  specialtyId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** Represents an update to a `SponsoredContentTeaserSpecialty`. Fields that are set will be updated. */
export type SponsoredContentTeaserSpecialtyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  specialtyId?: InputMaybe<Scalars['Int']>;
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
};

export type SponsoredContentTeaserTargetList = {
  __typename?: 'SponsoredContentTeaserTargetList';
  createdAt: Scalars['Datetime'];
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  sponsoredContentTeaserId: Scalars['Int'];
  /** Reads a single `TargetList` that is related to this `SponsoredContentTeaserTargetList`. */
  targetList?: Maybe<TargetList>;
  targetListId: Scalars['Int'];
};

/**
 * A condition to be used against `SponsoredContentTeaserTargetList` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SponsoredContentTeaserTargetListCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetListId` field. */
  targetListId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `SponsoredContentTeaserTargetList` object types. All fields are combined with a logical ‘and.’ */
export type SponsoredContentTeaserTargetListFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SponsoredContentTeaserTargetListFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SponsoredContentTeaserTargetListFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SponsoredContentTeaserTargetListFilter>>;
  /** Filter by the object’s `sponsoredContentTeaserId` field. */
  sponsoredContentTeaserId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `targetListId` field. */
  targetListId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SponsoredContentTeaserTargetList` */
export type SponsoredContentTeaserTargetListInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  sponsoredContentTeaserId: Scalars['Int'];
  targetListId: Scalars['Int'];
};

/** Represents an update to a `SponsoredContentTeaserTargetList`. Fields that are set will be updated. */
export type SponsoredContentTeaserTargetListPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  sponsoredContentTeaserId?: InputMaybe<Scalars['Int']>;
  targetListId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `SponsoredContentTeaserTargetList` values. */
export type SponsoredContentTeaserTargetListsConnection = {
  __typename?: 'SponsoredContentTeaserTargetListsConnection';
  /** A list of edges which contains the `SponsoredContentTeaserTargetList` and cursor to aid in pagination. */
  edges: Array<SponsoredContentTeaserTargetListsEdge>;
  /** A list of `SponsoredContentTeaserTargetList` objects. */
  nodes: Array<SponsoredContentTeaserTargetList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SponsoredContentTeaserTargetList` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SponsoredContentTeaserTargetList` edge in the connection. */
export type SponsoredContentTeaserTargetListsEdge = {
  __typename?: 'SponsoredContentTeaserTargetListsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SponsoredContentTeaserTargetList` at the end of the edge. */
  node: SponsoredContentTeaserTargetList;
};

/** Methods to use when ordering `SponsoredContentTeaserTargetList`. */
export enum SponsoredContentTeaserTargetListsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SponsoredContentTeaserIdAsc = 'SPONSORED_CONTENT_TEASER_ID_ASC',
  SponsoredContentTeaserIdDesc = 'SPONSORED_CONTENT_TEASER_ID_DESC',
  TargetListIdAsc = 'TARGET_LIST_ID_ASC',
  TargetListIdDesc = 'TARGET_LIST_ID_DESC'
}

/** A connection to a list of `SponsoredContentTeaser` values. */
export type SponsoredContentTeasersConnection = {
  __typename?: 'SponsoredContentTeasersConnection';
  /** A list of edges which contains the `SponsoredContentTeaser` and cursor to aid in pagination. */
  edges: Array<SponsoredContentTeasersEdge>;
  /** A list of `SponsoredContentTeaser` objects. */
  nodes: Array<SponsoredContentTeaser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SponsoredContentTeaser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SponsoredContentTeaser` edge in the connection. */
export type SponsoredContentTeasersEdge = {
  __typename?: 'SponsoredContentTeasersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SponsoredContentTeaser` at the end of the edge. */
  node: SponsoredContentTeaser;
};

/** Methods to use when ordering `SponsoredContentTeaser`. */
export enum SponsoredContentTeasersOrderBy {
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  ContentIdAsc = 'CONTENT_ID_ASC',
  ContentIdDesc = 'CONTENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  Natural = 'NATURAL',
  PlacementsCampaignIdAsc = 'PLACEMENTS_CAMPAIGN_ID_ASC',
  PlacementsCampaignIdDesc = 'PLACEMENTS_CAMPAIGN_ID_DESC',
  PlacementsLineItemIdAsc = 'PLACEMENTS_LINE_ITEM_ID_ASC',
  PlacementsLineItemIdDesc = 'PLACEMENTS_LINE_ITEM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  StartsAtAsc = 'STARTS_AT_ASC',
  StartsAtDesc = 'STARTS_AT_DESC',
  TargetingTypeAsc = 'TARGETING_TYPE_ASC',
  TargetingTypeDesc = 'TARGETING_TYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** A target list. */
export type TargetList = {
  __typename?: 'TargetList';
  bannerPlacementLineItemIdsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `BannerTargeting`. */
  bannerTargetings: BannerTargetingsConnection;
  clicksCreativeIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  clicksPlacementLineItemIdsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Optional comment with a more detailed description of the target list. */
  description?: Maybe<Scalars['String']>;
  fmaCreativeIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fmaPlacementLineItemIdsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID for the target group. */
  id: Scalars['Int'];
  /** A name describing the target list. */
  name: Scalars['String'];
  npiCount?: Maybe<Scalars['Int']>;
  placementLineItemIdsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaserTargetLists: SponsoredContentTeaserTargetListsConnection;
  /** Date and time in UTC when the target list was most recently uploaded. Defaults to the current time. */
  uploadedAt: Scalars['Datetime'];
  /** Filename of the file that was most recently uploaded as the target list. */
  uploadedFilename: Scalars['String'];
};


/** A target list. */
export type TargetListBannerPlacementLineItemIdsListArgs = {
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListBannerTargetingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BannerTargetingCondition>;
  filter?: InputMaybe<BannerTargetingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BannerTargetingsOrderBy>>;
};


/** A target list. */
export type TargetListClicksCreativeIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListClicksPlacementLineItemIdsListArgs = {
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListFmaCreativeIdsListArgs = {
  filter?: InputMaybe<IntFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListFmaPlacementLineItemIdsListArgs = {
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListPlacementLineItemIdsListArgs = {
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A target list. */
export type TargetListSponsoredContentTeaserTargetListsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SponsoredContentTeaserTargetListCondition>;
  filter?: InputMaybe<SponsoredContentTeaserTargetListFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};

/**
 * A condition to be used against `TargetList` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TargetListCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uploadedAt` field. */
  uploadedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `uploadedFilename` field. */
  uploadedFilename?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TargetList` object types. All fields are combined with a logical ‘and.’ */
export type TargetListFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TargetListFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TargetListFilter>;
  /** Filter by the object’s `npiCount` field. */
  npiCount?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TargetListFilter>>;
  /** Filter by the object’s `uploadedAt` field. */
  uploadedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uploadedFilename` field. */
  uploadedFilename?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TargetList` */
export type TargetListInput = {
  /** Optional comment with a more detailed description of the target list. */
  description?: InputMaybe<Scalars['String']>;
  /** ID for the target group. */
  id?: InputMaybe<Scalars['Int']>;
  /** A name describing the target list. */
  name: Scalars['String'];
  /** Date and time in UTC when the target list was most recently uploaded. Defaults to the current time. */
  uploadedAt?: InputMaybe<Scalars['Datetime']>;
  /** Filename of the file that was most recently uploaded as the target list. */
  uploadedFilename: Scalars['String'];
};

/** Represents an update to a `TargetList`. Fields that are set will be updated. */
export type TargetListPatch = {
  /** Optional comment with a more detailed description of the target list. */
  description?: InputMaybe<Scalars['String']>;
  /** ID for the target group. */
  id?: InputMaybe<Scalars['Int']>;
  /** A name describing the target list. */
  name?: InputMaybe<Scalars['String']>;
  /** Date and time in UTC when the target list was most recently uploaded. Defaults to the current time. */
  uploadedAt?: InputMaybe<Scalars['Datetime']>;
  /** Filename of the file that was most recently uploaded as the target list. */
  uploadedFilename?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TargetList` values. */
export type TargetListsConnection = {
  __typename?: 'TargetListsConnection';
  /** A list of edges which contains the `TargetList` and cursor to aid in pagination. */
  edges: Array<TargetListsEdge>;
  /** A list of `TargetList` objects. */
  nodes: Array<TargetList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TargetList` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TargetList` edge in the connection. */
export type TargetListsEdge = {
  __typename?: 'TargetListsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TargetList` at the end of the edge. */
  node: TargetList;
};

/** Methods to use when ordering `TargetList`. */
export enum TargetListsOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UploadedAtAsc = 'UPLOADED_AT_ASC',
  UploadedAtDesc = 'UPLOADED_AT_DESC',
  UploadedFilenameAsc = 'UPLOADED_FILENAME_ASC',
  UploadedFilenameDesc = 'UPLOADED_FILENAME_DESC'
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateCampaignLabel` mutation. */
export type UpdateCampaignLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  labelId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `CampaignLabel` being updated. */
  patch: CampaignLabelPatch;
  placementsCampaignId: Scalars['String'];
};

/** The output of our update `CampaignLabel` mutation. */
export type UpdateCampaignLabelPayload = {
  __typename?: 'UpdateCampaignLabelPayload';
  /** The `CampaignLabel` that was updated by this mutation. */
  campaignLabel?: Maybe<CampaignLabel>;
  /** An edge for our `CampaignLabel`. May be used by Relay 1. */
  campaignLabelEdge?: Maybe<CampaignLabelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Label` that is related to this `CampaignLabel`. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `CampaignLabel` mutation. */
export type UpdateCampaignLabelPayloadCampaignLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignLabelsOrderBy>>;
};

/** All input for the `updateClicksCreative` mutation. */
export type UpdateClicksCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the creative. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ClicksCreative` being updated. */
  patch: ClicksCreativePatch;
};

/** The output of our update `ClicksCreative` mutation. */
export type UpdateClicksCreativePayload = {
  __typename?: 'UpdateClicksCreativePayload';
  /** The `ClicksCreative` that was updated by this mutation. */
  clicksCreative?: Maybe<ClicksCreative>;
  /** An edge for our `ClicksCreative`. May be used by Relay 1. */
  clicksCreativeEdge?: Maybe<ClicksCreativesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `ClicksCreative` mutation. */
export type UpdateClicksCreativePayloadClicksCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<ClicksCreativesOrderBy>>;
};

/** All input for the `updateFmaCreative` mutation. */
export type UpdateFmaCreativeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the creative. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `FmaCreative` being updated. */
  patch: FmaCreativePatch;
};

/** The output of our update `FmaCreative` mutation. */
export type UpdateFmaCreativePayload = {
  __typename?: 'UpdateFmaCreativePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FmaCreative` that was updated by this mutation. */
  fmaCreative?: Maybe<FmaCreative>;
  /** An edge for our `FmaCreative`. May be used by Relay 1. */
  fmaCreativeEdge?: Maybe<FmaCreativesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `FmaCreative` mutation. */
export type UpdateFmaCreativePayloadFmaCreativeEdgeArgs = {
  orderBy?: InputMaybe<Array<FmaCreativesOrderBy>>;
};

/** All input for the `updateLabelByName` mutation. */
export type UpdateLabelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Label` being updated. */
  patch: LabelPatch;
};

/** All input for the `updateLabel` mutation. */
export type UpdateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Label` being updated. */
  patch: LabelPatch;
};

/** The output of our update `Label` mutation. */
export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Label` that was updated by this mutation. */
  label?: Maybe<Label>;
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge?: Maybe<LabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Label` mutation. */
export type UpdateLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};

/** All input for the `updateLineItemLabel` mutation. */
export type UpdateLineItemLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  labelId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `LineItemLabel` being updated. */
  patch: LineItemLabelPatch;
  placementsLineItemId: Scalars['String'];
};

/** The output of our update `LineItemLabel` mutation. */
export type UpdateLineItemLabelPayload = {
  __typename?: 'UpdateLineItemLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Label` that is related to this `LineItemLabel`. */
  label?: Maybe<Label>;
  /** The `LineItemLabel` that was updated by this mutation. */
  lineItemLabel?: Maybe<LineItemLabel>;
  /** An edge for our `LineItemLabel`. May be used by Relay 1. */
  lineItemLabelEdge?: Maybe<LineItemLabelsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LineItemLabel` mutation. */
export type UpdateLineItemLabelPayloadLineItemLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LineItemLabelsOrderBy>>;
};

/** All input for the `updateProfession` mutation. */
export type UpdateProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Profession` being updated. */
  patch: ProfessionPatch;
};

/** The output of our update `Profession` mutation. */
export type UpdateProfessionPayload = {
  __typename?: 'UpdateProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profession` that was updated by this mutation. */
  profession?: Maybe<Profession>;
  /** An edge for our `Profession`. May be used by Relay 1. */
  professionEdge?: Maybe<ProfessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Profession` mutation. */
export type UpdateProfessionPayloadProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProfessionsOrderBy>>;
};

/** All input for the `updateSpecialty` mutation. */
export type UpdateSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Specialty` being updated. */
  patch: SpecialtyPatch;
};

/** The output of our update `Specialty` mutation. */
export type UpdateSpecialtyPayload = {
  __typename?: 'UpdateSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Specialty` that was updated by this mutation. */
  specialty?: Maybe<Specialty>;
  /** An edge for our `Specialty`. May be used by Relay 1. */
  specialtyEdge?: Maybe<SpecialtiesEdge>;
};


/** The output of our update `Specialty` mutation. */
export type UpdateSpecialtyPayloadSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SpecialtiesOrderBy>>;
};

/** All input for the `updateSponsoredContentTeaser` mutation. */
export type UpdateSponsoredContentTeaserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `SponsoredContentTeaser` being updated. */
  patch: SponsoredContentTeaserPatch;
};

/** The output of our update `SponsoredContentTeaser` mutation. */
export type UpdateSponsoredContentTeaserPayload = {
  __typename?: 'UpdateSponsoredContentTeaserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SponsoredContentTeaser` that was updated by this mutation. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** An edge for our `SponsoredContentTeaser`. May be used by Relay 1. */
  sponsoredContentTeaserEdge?: Maybe<SponsoredContentTeasersEdge>;
};


/** The output of our update `SponsoredContentTeaser` mutation. */
export type UpdateSponsoredContentTeaserPayloadSponsoredContentTeaserEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeasersOrderBy>>;
};

/** All input for the `updateSponsoredContentTeaserProfession` mutation. */
export type UpdateSponsoredContentTeaserProfessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SponsoredContentTeaserProfession` being updated. */
  patch: SponsoredContentTeaserProfessionPatch;
  professionId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our update `SponsoredContentTeaserProfession` mutation. */
export type UpdateSponsoredContentTeaserProfessionPayload = {
  __typename?: 'UpdateSponsoredContentTeaserProfessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Profession` that is related to this `SponsoredContentTeaserProfession`. */
  profession?: Maybe<Profession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserProfession`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserProfession` that was updated by this mutation. */
  sponsoredContentTeaserProfession?: Maybe<SponsoredContentTeaserProfession>;
  /** An edge for our `SponsoredContentTeaserProfession`. May be used by Relay 1. */
  sponsoredContentTeaserProfessionEdge?: Maybe<SponsoredContentTeaserProfessionsEdge>;
};


/** The output of our update `SponsoredContentTeaserProfession` mutation. */
export type UpdateSponsoredContentTeaserProfessionPayloadSponsoredContentTeaserProfessionEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserProfessionsOrderBy>>;
};

/** All input for the `updateSponsoredContentTeaserSpecialty` mutation. */
export type UpdateSponsoredContentTeaserSpecialtyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SponsoredContentTeaserSpecialty` being updated. */
  patch: SponsoredContentTeaserSpecialtyPatch;
  specialtyId: Scalars['Int'];
  sponsoredContentTeaserId: Scalars['Int'];
};

/** The output of our update `SponsoredContentTeaserSpecialty` mutation. */
export type UpdateSponsoredContentTeaserSpecialtyPayload = {
  __typename?: 'UpdateSponsoredContentTeaserSpecialtyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Specialty` that is related to this `SponsoredContentTeaserSpecialty`. */
  specialty?: Maybe<Specialty>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserSpecialty`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserSpecialty` that was updated by this mutation. */
  sponsoredContentTeaserSpecialty?: Maybe<SponsoredContentTeaserSpecialty>;
  /** An edge for our `SponsoredContentTeaserSpecialty`. May be used by Relay 1. */
  sponsoredContentTeaserSpecialtyEdge?: Maybe<SponsoredContentTeaserSpecialtiesEdge>;
};


/** The output of our update `SponsoredContentTeaserSpecialty` mutation. */
export type UpdateSponsoredContentTeaserSpecialtyPayloadSponsoredContentTeaserSpecialtyEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserSpecialtiesOrderBy>>;
};

/** All input for the `updateSponsoredContentTeaserTargetList` mutation. */
export type UpdateSponsoredContentTeaserTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SponsoredContentTeaserTargetList` being updated. */
  patch: SponsoredContentTeaserTargetListPatch;
  sponsoredContentTeaserId: Scalars['Int'];
  targetListId: Scalars['Int'];
};

/** The output of our update `SponsoredContentTeaserTargetList` mutation. */
export type UpdateSponsoredContentTeaserTargetListPayload = {
  __typename?: 'UpdateSponsoredContentTeaserTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SponsoredContentTeaser` that is related to this `SponsoredContentTeaserTargetList`. */
  sponsoredContentTeaser?: Maybe<SponsoredContentTeaser>;
  /** The `SponsoredContentTeaserTargetList` that was updated by this mutation. */
  sponsoredContentTeaserTargetList?: Maybe<SponsoredContentTeaserTargetList>;
  /** An edge for our `SponsoredContentTeaserTargetList`. May be used by Relay 1. */
  sponsoredContentTeaserTargetListEdge?: Maybe<SponsoredContentTeaserTargetListsEdge>;
  /** Reads a single `TargetList` that is related to this `SponsoredContentTeaserTargetList`. */
  targetList?: Maybe<TargetList>;
};


/** The output of our update `SponsoredContentTeaserTargetList` mutation. */
export type UpdateSponsoredContentTeaserTargetListPayloadSponsoredContentTeaserTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<SponsoredContentTeaserTargetListsOrderBy>>;
};

/** All input for the `updateTargetList` mutation. */
export type UpdateTargetListInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID for the target group. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TargetList` being updated. */
  patch: TargetListPatch;
};

/** The output of our update `TargetList` mutation. */
export type UpdateTargetListPayload = {
  __typename?: 'UpdateTargetListPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TargetList` that was updated by this mutation. */
  targetList?: Maybe<TargetList>;
  /** An edge for our `TargetList`. May be used by Relay 1. */
  targetListEdge?: Maybe<TargetListsEdge>;
};


/** The output of our update `TargetList` mutation. */
export type UpdateTargetListPayloadTargetListEdgeArgs = {
  orderBy?: InputMaybe<Array<TargetListsOrderBy>>;
};

export type CreateTargetListMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  uploadedFilename: Scalars['String'];
}>;


export type CreateTargetListMutation = { __typename?: 'Mutation', createTargetList?: { __typename?: 'CreateTargetListPayload', targetList?: { __typename?: 'TargetList', id: number, name: string, description?: string | null, uploadedFilename: string, uploadedAt: any, npiCount?: number | null, placementLineItemIdsList?: Array<string | null> | null } | null } | null };

export type UpdateTargetListMutationVariables = Exact<{
  id: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  uploadedFilename?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTargetListMutation = { __typename?: 'Mutation', updateTargetList?: { __typename?: 'UpdateTargetListPayload', clientMutationId?: string | null, targetList?: { __typename?: 'TargetList', id: number, name: string, description?: string | null, uploadedFilename: string, uploadedAt: any, npiCount?: number | null, placementLineItemIdsList?: Array<string | null> | null } | null } | null };

export type SetTargetListNpisMutationVariables = Exact<{
  id: Scalars['Int'];
  npis: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type SetTargetListNpisMutation = { __typename?: 'Mutation', setTargetListNpis?: { __typename?: 'SetTargetListNpisPayload', clientMutationId?: string | null, query?: { __typename?: 'Query', targetList?: { __typename?: 'TargetList', id: number, name: string, description?: string | null, uploadedFilename: string, uploadedAt: any, npiCount?: number | null, placementLineItemIdsList?: Array<string | null> | null } | null } | null } | null };

export type DuplicateClicksCreativeMutationVariables = Exact<{
  existingId: Scalars['Int'];
}>;


export type DuplicateClicksCreativeMutation = { __typename?: 'Mutation', duplicateClicksCreative?: { __typename?: 'DuplicateClicksCreativePayload', clientMutationId?: string | null, clicksCreative?: { __typename?: 'ClicksCreative', id: number } | null } | null };

export type UpdateFmaCreativeMutationVariables = Exact<{
  id: Scalars['Int'];
  patch: FmaCreativePatch;
  targetListIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type UpdateFmaCreativeMutation = { __typename?: 'Mutation', updateFmaCreative?: { __typename?: 'UpdateFmaCreativePayload', clientMutationId?: string | null, fmaCreative?: { __typename?: 'FmaCreative', id: number } | null } | null, setFmaCreativeTargetLists?: { __typename?: 'SetFmaCreativeTargetListsPayload', clientMutationId?: string | null } | null };

export type DuplicateFmaCreativeMutationVariables = Exact<{
  existingId: Scalars['Int'];
}>;


export type DuplicateFmaCreativeMutation = { __typename?: 'Mutation', duplicateFmaCreative?: { __typename?: 'DuplicateFmaCreativePayload', clientMutationId?: string | null, fmaCreative?: { __typename?: 'FmaCreative', id: number } | null } | null };

export type CreateFmaCreativeMutationVariables = Exact<{
  input: CreateFmaCreativeInput;
}>;


export type CreateFmaCreativeMutation = { __typename?: 'Mutation', createFmaCreative?: { __typename?: 'CreateFmaCreativePayload', clientMutationId?: string | null, fmaCreative?: { __typename?: 'FmaCreative', id: number } | null } | null };

export type SetFmaCreativeTargetListsMutationVariables = Exact<{
  input: SetFmaCreativeTargetListsInput;
}>;


export type SetFmaCreativeTargetListsMutation = { __typename?: 'Mutation', setFmaCreativeTargetLists?: { __typename?: 'SetFmaCreativeTargetListsPayload', clientMutationId?: string | null } | null };

export type UpdateClicksCreativeMutationVariables = Exact<{
  id: Scalars['Int'];
  patch: ClicksCreativePatch;
  daysToHide: Array<InputMaybe<DayOfWeekType>> | InputMaybe<DayOfWeekType>;
  targetListIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  professionIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type UpdateClicksCreativeMutation = { __typename?: 'Mutation', updateClicksCreative?: { __typename?: 'UpdateClicksCreativePayload', clientMutationId?: string | null, clicksCreative?: { __typename?: 'ClicksCreative', id: number } | null } | null, setClicksCreativeHideOnDays?: { __typename?: 'SetClicksCreativeHideOnDaysPayload', clientMutationId?: string | null } | null, setClicksCreativeProfessions?: { __typename?: 'SetClicksCreativeProfessionsPayload', clientMutationId?: string | null } | null, setClicksCreativeSpecialties?: { __typename?: 'SetClicksCreativeSpecialtiesPayload', clientMutationId?: string | null } | null, setClicksCreativeTargetLists?: { __typename?: 'SetClicksCreativeTargetListsPayload', clientMutationId?: string | null } | null };

export type CreateClicksCreativeMutationVariables = Exact<{
  input: CreateClicksCreativeInput;
}>;


export type CreateClicksCreativeMutation = { __typename?: 'Mutation', createClicksCreative?: { __typename?: 'CreateClicksCreativePayload', clientMutationId?: string | null, clicksCreative?: { __typename?: 'ClicksCreative', id: number } | null } | null };

export type SetClicksCreativeListsMutationVariables = Exact<{
  id: Scalars['Int'];
  daysToHide: Array<InputMaybe<DayOfWeekType>> | InputMaybe<DayOfWeekType>;
  targetListIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  professionIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type SetClicksCreativeListsMutation = { __typename?: 'Mutation', setClicksCreativeHideOnDays?: { __typename?: 'SetClicksCreativeHideOnDaysPayload', clientMutationId?: string | null } | null, setClicksCreativeProfessions?: { __typename?: 'SetClicksCreativeProfessionsPayload', clientMutationId?: string | null } | null, setClicksCreativeSpecialties?: { __typename?: 'SetClicksCreativeSpecialtiesPayload', clientMutationId?: string | null } | null, setClicksCreativeTargetLists?: { __typename?: 'SetClicksCreativeTargetListsPayload', clientMutationId?: string | null } | null };

export type ArchiveLabelMutationVariables = Exact<{
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  updatedBy: Scalars['String'];
}>;


export type ArchiveLabelMutation = { __typename?: 'Mutation', updateLabel?: { __typename?: 'UpdateLabelPayload', label?: { __typename?: 'Label', id: any, name: string, description?: string | null, isArchived: boolean } | null } | null };

export type DeleteLineItemLabelMutationVariables = Exact<{
  placementsLineItemId: Scalars['String'];
  labelId: Scalars['UUID'];
}>;


export type DeleteLineItemLabelMutation = { __typename?: 'Mutation', deleteLineItemLabel?: { __typename?: 'DeleteLineItemLabelPayload', lineItemLabel?: { __typename?: 'LineItemLabel', placementsLineItemId: string, labelId: any } | null } | null };

export type DeleteCampaignLabelMutationVariables = Exact<{
  placementsCampaignId: Scalars['String'];
  labelId: Scalars['UUID'];
}>;


export type DeleteCampaignLabelMutation = { __typename?: 'Mutation', deleteCampaignLabel?: { __typename?: 'DeleteCampaignLabelPayload', campaignLabel?: { __typename?: 'CampaignLabel', placementsCampaignId: string, labelId: any } | null } | null };

export type CreateSponsoredContentTeaserMutationVariables = Exact<{
  input: CreateSponsoredContentTeaserInput;
}>;


export type CreateSponsoredContentTeaserMutation = { __typename?: 'Mutation', createSponsoredContentTeaser?: { __typename?: 'CreateSponsoredContentTeaserPayload', clientMutationId?: string | null, sponsoredContentTeaser?: { __typename?: 'SponsoredContentTeaser', id: number } | null } | null };

export type UpdateSponsoredContentTeaserMutationVariables = Exact<{
  id: Scalars['Int'];
  patch: SponsoredContentTeaserPatch;
  targetListIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  professionIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type UpdateSponsoredContentTeaserMutation = { __typename?: 'Mutation', updateSponsoredContentTeaser?: { __typename?: 'UpdateSponsoredContentTeaserPayload', clientMutationId?: string | null, sponsoredContentTeaser?: { __typename?: 'SponsoredContentTeaser', id: number } | null } | null, setSponsoredContentTeaserSpecialties?: { __typename?: 'SetSponsoredContentTeaserSpecialtiesPayload', clientMutationId?: string | null } | null, setSponsoredContentTeaserProfessions?: { __typename?: 'SetSponsoredContentTeaserProfessionsPayload', clientMutationId?: string | null } | null, setSponsoredContentTeaserTargetLists?: { __typename?: 'SetSponsoredContentTeaserTargetListsPayload', clientMutationId?: string | null } | null };

export type SetSponsoredContentTeaserListsMutationVariables = Exact<{
  id: Scalars['Int'];
  targetListIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  professionIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  specialtyIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type SetSponsoredContentTeaserListsMutation = { __typename?: 'Mutation', setSponsoredContentTeaserProfessions?: { __typename?: 'SetSponsoredContentTeaserProfessionsPayload', clientMutationId?: string | null } | null, setSponsoredContentTeaserSpecialties?: { __typename?: 'SetSponsoredContentTeaserSpecialtiesPayload', clientMutationId?: string | null } | null, setSponsoredContentTeaserTargetLists?: { __typename?: 'SetSponsoredContentTeaserTargetListsPayload', clientMutationId?: string | null } | null };

export type TargetListsPlacementLineItemIdsListQueryVariables = Exact<{
  includes: Scalars['String'];
}>;


export type TargetListsPlacementLineItemIdsListQuery = { __typename?: 'Query', targetLists?: { __typename?: 'TargetListsConnection', nodes: Array<{ __typename?: 'TargetList', id: number, name: string, description?: string | null, placementLineItemIdsList?: Array<string | null> | null }> } | null };

export type GetLabelsForLineItemQueryVariables = Exact<{
  lineItemId: Scalars['String'];
}>;


export type GetLabelsForLineItemQuery = { __typename?: 'Query', lineItemLabels?: { __typename?: 'LineItemLabelsConnection', nodes: Array<{ __typename?: 'LineItemLabel', labelId: any, label?: { __typename?: 'Label', id: any, name: string, isArchived: boolean } | null }> } | null };

export type GetLabelsForCampaignQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;


export type GetLabelsForCampaignQuery = { __typename?: 'Query', campaignLabels?: { __typename?: 'CampaignLabelsConnection', nodes: Array<{ __typename?: 'CampaignLabel', labelId: any, label?: { __typename?: 'Label', id: any, name: string, isArchived: boolean } | null }> } | null };


export const CreateTargetListDocument = gql`
    mutation CreateTargetList($name: String!, $description: String, $uploadedFilename: String!) {
  createTargetList(
    input: {targetList: {name: $name, description: $description, uploadedFilename: $uploadedFilename}}
  ) {
    targetList {
      id
      name
      description
      uploadedFilename
      uploadedAt
      npiCount
      placementLineItemIdsList
    }
  }
}
    `;
export type CreateTargetListMutationFn = Apollo.MutationFunction<CreateTargetListMutation, CreateTargetListMutationVariables>;

/**
 * __useCreateTargetListMutation__
 *
 * To run a mutation, you first call `useCreateTargetListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetListMutation, { data, loading, error }] = useCreateTargetListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      uploadedFilename: // value for 'uploadedFilename'
 *   },
 * });
 */
export function useCreateTargetListMutation(baseOptions?: Apollo.MutationHookOptions<CreateTargetListMutation, CreateTargetListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTargetListMutation, CreateTargetListMutationVariables>(CreateTargetListDocument, options);
      }
export type CreateTargetListMutationHookResult = ReturnType<typeof useCreateTargetListMutation>;
export type CreateTargetListMutationResult = Apollo.MutationResult<CreateTargetListMutation>;
export type CreateTargetListMutationOptions = Apollo.BaseMutationOptions<CreateTargetListMutation, CreateTargetListMutationVariables>;
export const UpdateTargetListDocument = gql`
    mutation UpdateTargetList($id: Int!, $description: String, $name: String, $uploadedFilename: String) {
  updateTargetList(
    input: {patch: {description: $description, name: $name, uploadedFilename: $uploadedFilename}, id: $id}
  ) {
    clientMutationId
    targetList {
      id
      name
      description
      uploadedFilename
      uploadedAt
      npiCount
      placementLineItemIdsList
    }
  }
}
    `;
export type UpdateTargetListMutationFn = Apollo.MutationFunction<UpdateTargetListMutation, UpdateTargetListMutationVariables>;

/**
 * __useUpdateTargetListMutation__
 *
 * To run a mutation, you first call `useUpdateTargetListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetListMutation, { data, loading, error }] = useUpdateTargetListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      uploadedFilename: // value for 'uploadedFilename'
 *   },
 * });
 */
export function useUpdateTargetListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTargetListMutation, UpdateTargetListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTargetListMutation, UpdateTargetListMutationVariables>(UpdateTargetListDocument, options);
      }
export type UpdateTargetListMutationHookResult = ReturnType<typeof useUpdateTargetListMutation>;
export type UpdateTargetListMutationResult = Apollo.MutationResult<UpdateTargetListMutation>;
export type UpdateTargetListMutationOptions = Apollo.BaseMutationOptions<UpdateTargetListMutation, UpdateTargetListMutationVariables>;
export const SetTargetListNpisDocument = gql`
    mutation SetTargetListNpis($id: Int!, $npis: [Int]!) {
  setTargetListNpis(input: {targetListId: $id, npis: $npis}) {
    clientMutationId
    query {
      targetList(id: $id) {
        id
        name
        description
        uploadedFilename
        uploadedAt
        npiCount
        placementLineItemIdsList
      }
    }
  }
}
    `;
export type SetTargetListNpisMutationFn = Apollo.MutationFunction<SetTargetListNpisMutation, SetTargetListNpisMutationVariables>;

/**
 * __useSetTargetListNpisMutation__
 *
 * To run a mutation, you first call `useSetTargetListNpisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTargetListNpisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTargetListNpisMutation, { data, loading, error }] = useSetTargetListNpisMutation({
 *   variables: {
 *      id: // value for 'id'
 *      npis: // value for 'npis'
 *   },
 * });
 */
export function useSetTargetListNpisMutation(baseOptions?: Apollo.MutationHookOptions<SetTargetListNpisMutation, SetTargetListNpisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTargetListNpisMutation, SetTargetListNpisMutationVariables>(SetTargetListNpisDocument, options);
      }
export type SetTargetListNpisMutationHookResult = ReturnType<typeof useSetTargetListNpisMutation>;
export type SetTargetListNpisMutationResult = Apollo.MutationResult<SetTargetListNpisMutation>;
export type SetTargetListNpisMutationOptions = Apollo.BaseMutationOptions<SetTargetListNpisMutation, SetTargetListNpisMutationVariables>;
export const DuplicateClicksCreativeDocument = gql`
    mutation DuplicateClicksCreative($existingId: Int!) {
  duplicateClicksCreative(input: {existingId: $existingId}) {
    clientMutationId
    clicksCreative {
      id
    }
  }
}
    `;
export type DuplicateClicksCreativeMutationFn = Apollo.MutationFunction<DuplicateClicksCreativeMutation, DuplicateClicksCreativeMutationVariables>;

/**
 * __useDuplicateClicksCreativeMutation__
 *
 * To run a mutation, you first call `useDuplicateClicksCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateClicksCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateClicksCreativeMutation, { data, loading, error }] = useDuplicateClicksCreativeMutation({
 *   variables: {
 *      existingId: // value for 'existingId'
 *   },
 * });
 */
export function useDuplicateClicksCreativeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateClicksCreativeMutation, DuplicateClicksCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateClicksCreativeMutation, DuplicateClicksCreativeMutationVariables>(DuplicateClicksCreativeDocument, options);
      }
export type DuplicateClicksCreativeMutationHookResult = ReturnType<typeof useDuplicateClicksCreativeMutation>;
export type DuplicateClicksCreativeMutationResult = Apollo.MutationResult<DuplicateClicksCreativeMutation>;
export type DuplicateClicksCreativeMutationOptions = Apollo.BaseMutationOptions<DuplicateClicksCreativeMutation, DuplicateClicksCreativeMutationVariables>;
export const UpdateFmaCreativeDocument = gql`
    mutation UpdateFmaCreative($id: Int!, $patch: FmaCreativePatch!, $targetListIds: [Int]!) {
  updateFmaCreative(input: {id: $id, patch: $patch}) {
    clientMutationId
    fmaCreative {
      id
    }
  }
  setFmaCreativeTargetLists(
    input: {fmaCreativeId: $id, targetListIds: $targetListIds}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateFmaCreativeMutationFn = Apollo.MutationFunction<UpdateFmaCreativeMutation, UpdateFmaCreativeMutationVariables>;

/**
 * __useUpdateFmaCreativeMutation__
 *
 * To run a mutation, you first call `useUpdateFmaCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFmaCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFmaCreativeMutation, { data, loading, error }] = useUpdateFmaCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *      targetListIds: // value for 'targetListIds'
 *   },
 * });
 */
export function useUpdateFmaCreativeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFmaCreativeMutation, UpdateFmaCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFmaCreativeMutation, UpdateFmaCreativeMutationVariables>(UpdateFmaCreativeDocument, options);
      }
export type UpdateFmaCreativeMutationHookResult = ReturnType<typeof useUpdateFmaCreativeMutation>;
export type UpdateFmaCreativeMutationResult = Apollo.MutationResult<UpdateFmaCreativeMutation>;
export type UpdateFmaCreativeMutationOptions = Apollo.BaseMutationOptions<UpdateFmaCreativeMutation, UpdateFmaCreativeMutationVariables>;
export const DuplicateFmaCreativeDocument = gql`
    mutation DuplicateFmaCreative($existingId: Int!) {
  duplicateFmaCreative(input: {existingId: $existingId}) {
    clientMutationId
    fmaCreative {
      id
    }
  }
}
    `;
export type DuplicateFmaCreativeMutationFn = Apollo.MutationFunction<DuplicateFmaCreativeMutation, DuplicateFmaCreativeMutationVariables>;

/**
 * __useDuplicateFmaCreativeMutation__
 *
 * To run a mutation, you first call `useDuplicateFmaCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFmaCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFmaCreativeMutation, { data, loading, error }] = useDuplicateFmaCreativeMutation({
 *   variables: {
 *      existingId: // value for 'existingId'
 *   },
 * });
 */
export function useDuplicateFmaCreativeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateFmaCreativeMutation, DuplicateFmaCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateFmaCreativeMutation, DuplicateFmaCreativeMutationVariables>(DuplicateFmaCreativeDocument, options);
      }
export type DuplicateFmaCreativeMutationHookResult = ReturnType<typeof useDuplicateFmaCreativeMutation>;
export type DuplicateFmaCreativeMutationResult = Apollo.MutationResult<DuplicateFmaCreativeMutation>;
export type DuplicateFmaCreativeMutationOptions = Apollo.BaseMutationOptions<DuplicateFmaCreativeMutation, DuplicateFmaCreativeMutationVariables>;
export const CreateFmaCreativeDocument = gql`
    mutation CreateFmaCreative($input: CreateFmaCreativeInput!) {
  createFmaCreative(input: $input) {
    clientMutationId
    fmaCreative {
      id
    }
  }
}
    `;
export type CreateFmaCreativeMutationFn = Apollo.MutationFunction<CreateFmaCreativeMutation, CreateFmaCreativeMutationVariables>;

/**
 * __useCreateFmaCreativeMutation__
 *
 * To run a mutation, you first call `useCreateFmaCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFmaCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFmaCreativeMutation, { data, loading, error }] = useCreateFmaCreativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFmaCreativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateFmaCreativeMutation, CreateFmaCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFmaCreativeMutation, CreateFmaCreativeMutationVariables>(CreateFmaCreativeDocument, options);
      }
export type CreateFmaCreativeMutationHookResult = ReturnType<typeof useCreateFmaCreativeMutation>;
export type CreateFmaCreativeMutationResult = Apollo.MutationResult<CreateFmaCreativeMutation>;
export type CreateFmaCreativeMutationOptions = Apollo.BaseMutationOptions<CreateFmaCreativeMutation, CreateFmaCreativeMutationVariables>;
export const SetFmaCreativeTargetListsDocument = gql`
    mutation SetFmaCreativeTargetLists($input: SetFmaCreativeTargetListsInput!) {
  setFmaCreativeTargetLists(input: $input) {
    clientMutationId
  }
}
    `;
export type SetFmaCreativeTargetListsMutationFn = Apollo.MutationFunction<SetFmaCreativeTargetListsMutation, SetFmaCreativeTargetListsMutationVariables>;

/**
 * __useSetFmaCreativeTargetListsMutation__
 *
 * To run a mutation, you first call `useSetFmaCreativeTargetListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFmaCreativeTargetListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFmaCreativeTargetListsMutation, { data, loading, error }] = useSetFmaCreativeTargetListsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFmaCreativeTargetListsMutation(baseOptions?: Apollo.MutationHookOptions<SetFmaCreativeTargetListsMutation, SetFmaCreativeTargetListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFmaCreativeTargetListsMutation, SetFmaCreativeTargetListsMutationVariables>(SetFmaCreativeTargetListsDocument, options);
      }
export type SetFmaCreativeTargetListsMutationHookResult = ReturnType<typeof useSetFmaCreativeTargetListsMutation>;
export type SetFmaCreativeTargetListsMutationResult = Apollo.MutationResult<SetFmaCreativeTargetListsMutation>;
export type SetFmaCreativeTargetListsMutationOptions = Apollo.BaseMutationOptions<SetFmaCreativeTargetListsMutation, SetFmaCreativeTargetListsMutationVariables>;
export const UpdateClicksCreativeDocument = gql`
    mutation UpdateClicksCreative($id: Int!, $patch: ClicksCreativePatch!, $daysToHide: [DayOfWeekType]!, $targetListIds: [Int]!, $professionIds: [Int]!, $specialtyIds: [Int]!) {
  updateClicksCreative(input: {patch: $patch, id: $id}) {
    clientMutationId
    clicksCreative {
      id
    }
  }
  setClicksCreativeHideOnDays(
    input: {clicksCreativeId: $id, daysToHide: $daysToHide}
  ) {
    clientMutationId
  }
  setClicksCreativeProfessions(
    input: {clicksCreativeId: $id, professionIds: $professionIds}
  ) {
    clientMutationId
  }
  setClicksCreativeSpecialties(
    input: {clicksCreativeId: $id, specialtyIds: $specialtyIds}
  ) {
    clientMutationId
  }
  setClicksCreativeTargetLists(
    input: {clicksCreativeId: $id, targetListIds: $targetListIds}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateClicksCreativeMutationFn = Apollo.MutationFunction<UpdateClicksCreativeMutation, UpdateClicksCreativeMutationVariables>;

/**
 * __useUpdateClicksCreativeMutation__
 *
 * To run a mutation, you first call `useUpdateClicksCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClicksCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClicksCreativeMutation, { data, loading, error }] = useUpdateClicksCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *      daysToHide: // value for 'daysToHide'
 *      targetListIds: // value for 'targetListIds'
 *      professionIds: // value for 'professionIds'
 *      specialtyIds: // value for 'specialtyIds'
 *   },
 * });
 */
export function useUpdateClicksCreativeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClicksCreativeMutation, UpdateClicksCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClicksCreativeMutation, UpdateClicksCreativeMutationVariables>(UpdateClicksCreativeDocument, options);
      }
export type UpdateClicksCreativeMutationHookResult = ReturnType<typeof useUpdateClicksCreativeMutation>;
export type UpdateClicksCreativeMutationResult = Apollo.MutationResult<UpdateClicksCreativeMutation>;
export type UpdateClicksCreativeMutationOptions = Apollo.BaseMutationOptions<UpdateClicksCreativeMutation, UpdateClicksCreativeMutationVariables>;
export const CreateClicksCreativeDocument = gql`
    mutation CreateClicksCreative($input: CreateClicksCreativeInput!) {
  createClicksCreative(input: $input) {
    clientMutationId
    clicksCreative {
      id
    }
  }
}
    `;
export type CreateClicksCreativeMutationFn = Apollo.MutationFunction<CreateClicksCreativeMutation, CreateClicksCreativeMutationVariables>;

/**
 * __useCreateClicksCreativeMutation__
 *
 * To run a mutation, you first call `useCreateClicksCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClicksCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClicksCreativeMutation, { data, loading, error }] = useCreateClicksCreativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClicksCreativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateClicksCreativeMutation, CreateClicksCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClicksCreativeMutation, CreateClicksCreativeMutationVariables>(CreateClicksCreativeDocument, options);
      }
export type CreateClicksCreativeMutationHookResult = ReturnType<typeof useCreateClicksCreativeMutation>;
export type CreateClicksCreativeMutationResult = Apollo.MutationResult<CreateClicksCreativeMutation>;
export type CreateClicksCreativeMutationOptions = Apollo.BaseMutationOptions<CreateClicksCreativeMutation, CreateClicksCreativeMutationVariables>;
export const SetClicksCreativeListsDocument = gql`
    mutation SetClicksCreativeLists($id: Int!, $daysToHide: [DayOfWeekType]!, $targetListIds: [Int]!, $professionIds: [Int]!, $specialtyIds: [Int]!) {
  setClicksCreativeHideOnDays(
    input: {clicksCreativeId: $id, daysToHide: $daysToHide}
  ) {
    clientMutationId
  }
  setClicksCreativeProfessions(
    input: {clicksCreativeId: $id, professionIds: $professionIds}
  ) {
    clientMutationId
  }
  setClicksCreativeSpecialties(
    input: {clicksCreativeId: $id, specialtyIds: $specialtyIds}
  ) {
    clientMutationId
  }
  setClicksCreativeTargetLists(
    input: {clicksCreativeId: $id, targetListIds: $targetListIds}
  ) {
    clientMutationId
  }
}
    `;
export type SetClicksCreativeListsMutationFn = Apollo.MutationFunction<SetClicksCreativeListsMutation, SetClicksCreativeListsMutationVariables>;

/**
 * __useSetClicksCreativeListsMutation__
 *
 * To run a mutation, you first call `useSetClicksCreativeListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClicksCreativeListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClicksCreativeListsMutation, { data, loading, error }] = useSetClicksCreativeListsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      daysToHide: // value for 'daysToHide'
 *      targetListIds: // value for 'targetListIds'
 *      professionIds: // value for 'professionIds'
 *      specialtyIds: // value for 'specialtyIds'
 *   },
 * });
 */
export function useSetClicksCreativeListsMutation(baseOptions?: Apollo.MutationHookOptions<SetClicksCreativeListsMutation, SetClicksCreativeListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClicksCreativeListsMutation, SetClicksCreativeListsMutationVariables>(SetClicksCreativeListsDocument, options);
      }
export type SetClicksCreativeListsMutationHookResult = ReturnType<typeof useSetClicksCreativeListsMutation>;
export type SetClicksCreativeListsMutationResult = Apollo.MutationResult<SetClicksCreativeListsMutation>;
export type SetClicksCreativeListsMutationOptions = Apollo.BaseMutationOptions<SetClicksCreativeListsMutation, SetClicksCreativeListsMutationVariables>;
export const ArchiveLabelDocument = gql`
    mutation ArchiveLabel($id: UUID!, $isArchived: Boolean!, $name: String!, $updatedBy: String!) {
  updateLabel(
    input: {id: $id, patch: {isArchived: $isArchived, name: $name, updatedBy: $updatedBy}}
  ) {
    label {
      id
      name
      description
      isArchived
    }
  }
}
    `;
export type ArchiveLabelMutationFn = Apollo.MutationFunction<ArchiveLabelMutation, ArchiveLabelMutationVariables>;

/**
 * __useArchiveLabelMutation__
 *
 * To run a mutation, you first call `useArchiveLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveLabelMutation, { data, loading, error }] = useArchiveLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *      name: // value for 'name'
 *      updatedBy: // value for 'updatedBy'
 *   },
 * });
 */
export function useArchiveLabelMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveLabelMutation, ArchiveLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveLabelMutation, ArchiveLabelMutationVariables>(ArchiveLabelDocument, options);
      }
export type ArchiveLabelMutationHookResult = ReturnType<typeof useArchiveLabelMutation>;
export type ArchiveLabelMutationResult = Apollo.MutationResult<ArchiveLabelMutation>;
export type ArchiveLabelMutationOptions = Apollo.BaseMutationOptions<ArchiveLabelMutation, ArchiveLabelMutationVariables>;
export const DeleteLineItemLabelDocument = gql`
    mutation DeleteLineItemLabel($placementsLineItemId: String!, $labelId: UUID!) {
  deleteLineItemLabel(
    input: {placementsLineItemId: $placementsLineItemId, labelId: $labelId}
  ) {
    lineItemLabel {
      placementsLineItemId
      labelId
    }
  }
}
    `;
export type DeleteLineItemLabelMutationFn = Apollo.MutationFunction<DeleteLineItemLabelMutation, DeleteLineItemLabelMutationVariables>;

/**
 * __useDeleteLineItemLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLineItemLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLineItemLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLineItemLabelMutation, { data, loading, error }] = useDeleteLineItemLabelMutation({
 *   variables: {
 *      placementsLineItemId: // value for 'placementsLineItemId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useDeleteLineItemLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLineItemLabelMutation, DeleteLineItemLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLineItemLabelMutation, DeleteLineItemLabelMutationVariables>(DeleteLineItemLabelDocument, options);
      }
export type DeleteLineItemLabelMutationHookResult = ReturnType<typeof useDeleteLineItemLabelMutation>;
export type DeleteLineItemLabelMutationResult = Apollo.MutationResult<DeleteLineItemLabelMutation>;
export type DeleteLineItemLabelMutationOptions = Apollo.BaseMutationOptions<DeleteLineItemLabelMutation, DeleteLineItemLabelMutationVariables>;
export const DeleteCampaignLabelDocument = gql`
    mutation DeleteCampaignLabel($placementsCampaignId: String!, $labelId: UUID!) {
  deleteCampaignLabel(
    input: {placementsCampaignId: $placementsCampaignId, labelId: $labelId}
  ) {
    campaignLabel {
      placementsCampaignId
      labelId
    }
  }
}
    `;
export type DeleteCampaignLabelMutationFn = Apollo.MutationFunction<DeleteCampaignLabelMutation, DeleteCampaignLabelMutationVariables>;

/**
 * __useDeleteCampaignLabelMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignLabelMutation, { data, loading, error }] = useDeleteCampaignLabelMutation({
 *   variables: {
 *      placementsCampaignId: // value for 'placementsCampaignId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useDeleteCampaignLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignLabelMutation, DeleteCampaignLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignLabelMutation, DeleteCampaignLabelMutationVariables>(DeleteCampaignLabelDocument, options);
      }
export type DeleteCampaignLabelMutationHookResult = ReturnType<typeof useDeleteCampaignLabelMutation>;
export type DeleteCampaignLabelMutationResult = Apollo.MutationResult<DeleteCampaignLabelMutation>;
export type DeleteCampaignLabelMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignLabelMutation, DeleteCampaignLabelMutationVariables>;
export const CreateSponsoredContentTeaserDocument = gql`
    mutation CreateSponsoredContentTeaser($input: CreateSponsoredContentTeaserInput!) {
  createSponsoredContentTeaser(input: $input) {
    clientMutationId
    sponsoredContentTeaser {
      id
    }
  }
}
    `;
export type CreateSponsoredContentTeaserMutationFn = Apollo.MutationFunction<CreateSponsoredContentTeaserMutation, CreateSponsoredContentTeaserMutationVariables>;

/**
 * __useCreateSponsoredContentTeaserMutation__
 *
 * To run a mutation, you first call `useCreateSponsoredContentTeaserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSponsoredContentTeaserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSponsoredContentTeaserMutation, { data, loading, error }] = useCreateSponsoredContentTeaserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSponsoredContentTeaserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSponsoredContentTeaserMutation, CreateSponsoredContentTeaserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSponsoredContentTeaserMutation, CreateSponsoredContentTeaserMutationVariables>(CreateSponsoredContentTeaserDocument, options);
      }
export type CreateSponsoredContentTeaserMutationHookResult = ReturnType<typeof useCreateSponsoredContentTeaserMutation>;
export type CreateSponsoredContentTeaserMutationResult = Apollo.MutationResult<CreateSponsoredContentTeaserMutation>;
export type CreateSponsoredContentTeaserMutationOptions = Apollo.BaseMutationOptions<CreateSponsoredContentTeaserMutation, CreateSponsoredContentTeaserMutationVariables>;
export const UpdateSponsoredContentTeaserDocument = gql`
    mutation UpdateSponsoredContentTeaser($id: Int!, $patch: SponsoredContentTeaserPatch!, $targetListIds: [Int]!, $professionIds: [Int]!, $specialtyIds: [Int]!) {
  updateSponsoredContentTeaser(input: {patch: $patch, id: $id}) {
    clientMutationId
    sponsoredContentTeaser {
      id
    }
  }
  setSponsoredContentTeaserSpecialties(
    input: {sponsoredContentTeaserId: $id, specialtyIds: $specialtyIds}
  ) {
    clientMutationId
  }
  setSponsoredContentTeaserProfessions(
    input: {sponsoredContentTeaserId: $id, professionIds: $professionIds}
  ) {
    clientMutationId
  }
  setSponsoredContentTeaserTargetLists(
    input: {sponsoredContentTeaserId: $id, targetListIds: $targetListIds}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateSponsoredContentTeaserMutationFn = Apollo.MutationFunction<UpdateSponsoredContentTeaserMutation, UpdateSponsoredContentTeaserMutationVariables>;

/**
 * __useUpdateSponsoredContentTeaserMutation__
 *
 * To run a mutation, you first call `useUpdateSponsoredContentTeaserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsoredContentTeaserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsoredContentTeaserMutation, { data, loading, error }] = useUpdateSponsoredContentTeaserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *      targetListIds: // value for 'targetListIds'
 *      professionIds: // value for 'professionIds'
 *      specialtyIds: // value for 'specialtyIds'
 *   },
 * });
 */
export function useUpdateSponsoredContentTeaserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSponsoredContentTeaserMutation, UpdateSponsoredContentTeaserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSponsoredContentTeaserMutation, UpdateSponsoredContentTeaserMutationVariables>(UpdateSponsoredContentTeaserDocument, options);
      }
export type UpdateSponsoredContentTeaserMutationHookResult = ReturnType<typeof useUpdateSponsoredContentTeaserMutation>;
export type UpdateSponsoredContentTeaserMutationResult = Apollo.MutationResult<UpdateSponsoredContentTeaserMutation>;
export type UpdateSponsoredContentTeaserMutationOptions = Apollo.BaseMutationOptions<UpdateSponsoredContentTeaserMutation, UpdateSponsoredContentTeaserMutationVariables>;
export const SetSponsoredContentTeaserListsDocument = gql`
    mutation SetSponsoredContentTeaserLists($id: Int!, $targetListIds: [Int]!, $professionIds: [Int]!, $specialtyIds: [Int]!) {
  setSponsoredContentTeaserProfessions(
    input: {sponsoredContentTeaserId: $id, professionIds: $professionIds}
  ) {
    clientMutationId
  }
  setSponsoredContentTeaserSpecialties(
    input: {sponsoredContentTeaserId: $id, specialtyIds: $specialtyIds}
  ) {
    clientMutationId
  }
  setSponsoredContentTeaserTargetLists(
    input: {sponsoredContentTeaserId: $id, targetListIds: $targetListIds}
  ) {
    clientMutationId
  }
}
    `;
export type SetSponsoredContentTeaserListsMutationFn = Apollo.MutationFunction<SetSponsoredContentTeaserListsMutation, SetSponsoredContentTeaserListsMutationVariables>;

/**
 * __useSetSponsoredContentTeaserListsMutation__
 *
 * To run a mutation, you first call `useSetSponsoredContentTeaserListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSponsoredContentTeaserListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSponsoredContentTeaserListsMutation, { data, loading, error }] = useSetSponsoredContentTeaserListsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      targetListIds: // value for 'targetListIds'
 *      professionIds: // value for 'professionIds'
 *      specialtyIds: // value for 'specialtyIds'
 *   },
 * });
 */
export function useSetSponsoredContentTeaserListsMutation(baseOptions?: Apollo.MutationHookOptions<SetSponsoredContentTeaserListsMutation, SetSponsoredContentTeaserListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSponsoredContentTeaserListsMutation, SetSponsoredContentTeaserListsMutationVariables>(SetSponsoredContentTeaserListsDocument, options);
      }
export type SetSponsoredContentTeaserListsMutationHookResult = ReturnType<typeof useSetSponsoredContentTeaserListsMutation>;
export type SetSponsoredContentTeaserListsMutationResult = Apollo.MutationResult<SetSponsoredContentTeaserListsMutation>;
export type SetSponsoredContentTeaserListsMutationOptions = Apollo.BaseMutationOptions<SetSponsoredContentTeaserListsMutation, SetSponsoredContentTeaserListsMutationVariables>;
export const TargetListsPlacementLineItemIdsListDocument = gql`
    query TargetListsPlacementLineItemIdsList($includes: String!) {
  targetLists {
    nodes {
      id
      name
      description
      placementLineItemIdsList(filter: {includes: $includes})
    }
  }
}
    `;

/**
 * __useTargetListsPlacementLineItemIdsListQuery__
 *
 * To run a query within a React component, call `useTargetListsPlacementLineItemIdsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetListsPlacementLineItemIdsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetListsPlacementLineItemIdsListQuery({
 *   variables: {
 *      includes: // value for 'includes'
 *   },
 * });
 */
export function useTargetListsPlacementLineItemIdsListQuery(baseOptions: Apollo.QueryHookOptions<TargetListsPlacementLineItemIdsListQuery, TargetListsPlacementLineItemIdsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TargetListsPlacementLineItemIdsListQuery, TargetListsPlacementLineItemIdsListQueryVariables>(TargetListsPlacementLineItemIdsListDocument, options);
      }
export function useTargetListsPlacementLineItemIdsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TargetListsPlacementLineItemIdsListQuery, TargetListsPlacementLineItemIdsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TargetListsPlacementLineItemIdsListQuery, TargetListsPlacementLineItemIdsListQueryVariables>(TargetListsPlacementLineItemIdsListDocument, options);
        }
export type TargetListsPlacementLineItemIdsListQueryHookResult = ReturnType<typeof useTargetListsPlacementLineItemIdsListQuery>;
export type TargetListsPlacementLineItemIdsListLazyQueryHookResult = ReturnType<typeof useTargetListsPlacementLineItemIdsListLazyQuery>;
export type TargetListsPlacementLineItemIdsListQueryResult = Apollo.QueryResult<TargetListsPlacementLineItemIdsListQuery, TargetListsPlacementLineItemIdsListQueryVariables>;
export const GetLabelsForLineItemDocument = gql`
    query GetLabelsForLineItem($lineItemId: String!) {
  lineItemLabels(filter: {placementsLineItemId: {equalTo: $lineItemId}}) {
    nodes {
      labelId
      label {
        id
        name
        isArchived
      }
    }
  }
}
    `;

/**
 * __useGetLabelsForLineItemQuery__
 *
 * To run a query within a React component, call `useGetLabelsForLineItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsForLineItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsForLineItemQuery({
 *   variables: {
 *      lineItemId: // value for 'lineItemId'
 *   },
 * });
 */
export function useGetLabelsForLineItemQuery(baseOptions: Apollo.QueryHookOptions<GetLabelsForLineItemQuery, GetLabelsForLineItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsForLineItemQuery, GetLabelsForLineItemQueryVariables>(GetLabelsForLineItemDocument, options);
      }
export function useGetLabelsForLineItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsForLineItemQuery, GetLabelsForLineItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsForLineItemQuery, GetLabelsForLineItemQueryVariables>(GetLabelsForLineItemDocument, options);
        }
export type GetLabelsForLineItemQueryHookResult = ReturnType<typeof useGetLabelsForLineItemQuery>;
export type GetLabelsForLineItemLazyQueryHookResult = ReturnType<typeof useGetLabelsForLineItemLazyQuery>;
export type GetLabelsForLineItemQueryResult = Apollo.QueryResult<GetLabelsForLineItemQuery, GetLabelsForLineItemQueryVariables>;
export const GetLabelsForCampaignDocument = gql`
    query GetLabelsForCampaign($campaignId: String!) {
  campaignLabels(filter: {placementsCampaignId: {equalTo: $campaignId}}) {
    nodes {
      labelId
      label {
        id
        name
        isArchived
      }
    }
  }
}
    `;

/**
 * __useGetLabelsForCampaignQuery__
 *
 * To run a query within a React component, call `useGetLabelsForCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsForCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsForCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetLabelsForCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetLabelsForCampaignQuery, GetLabelsForCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsForCampaignQuery, GetLabelsForCampaignQueryVariables>(GetLabelsForCampaignDocument, options);
      }
export function useGetLabelsForCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsForCampaignQuery, GetLabelsForCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsForCampaignQuery, GetLabelsForCampaignQueryVariables>(GetLabelsForCampaignDocument, options);
        }
export type GetLabelsForCampaignQueryHookResult = ReturnType<typeof useGetLabelsForCampaignQuery>;
export type GetLabelsForCampaignLazyQueryHookResult = ReturnType<typeof useGetLabelsForCampaignLazyQuery>;
export type GetLabelsForCampaignQueryResult = Apollo.QueryResult<GetLabelsForCampaignQuery, GetLabelsForCampaignQueryVariables>;