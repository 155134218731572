import {
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  RadioButtonGroupInput,
  Loading,
  useGetOne,
  useRecordContext,
  FormDataConsumer,
  SaveButton,
  Toolbar,
  ToolbarProps,
  SelectInput,
} from 'react-admin';
import {
  CampaignLineItemsSelectInput,
  CurrentLineItemDateInputGroup,
  FormDivider,
  FormHeader,
  FormSectionHeader,
} from './form-components';
import { CreativesFormProps } from './fma-creatives';
import { ClicksCreativeTargetingType, SponsoredContentTeaser } from 'src/generated/client';
import { titleize } from 'inflection';
import { AutocompleteInput } from './shared';
import { range } from 'lodash';

const targetingTypeChoices = (
  Object.keys(ClicksCreativeTargetingType) as Array<keyof typeof ClicksCreativeTargetingType>
).map(targetingTypeKey => {
  const targetingType = ClicksCreativeTargetingType[targetingTypeKey];

  return {
    id: targetingType,
    name: titleize(targetingType),
  };
});

const SponsoredContentTeaserToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SponsoredContentTeaserForm = ({ type, ...rest }: CreativesFormProps) => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetOne('line_items', { id: record.placementsLineItemId || '' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  if (!data) return null;

  const lineItem = data;

  const defaultValues: SponsoredContentTeaser = {
    ...rest.defaultValues,
    startsAt: lineItem.startDate,
    endsAt: lineItem.endDate,
  };

  return (
    <SimpleForm
      {...rest}
      defaultValues={defaultValues}
      toolbar={<SponsoredContentTeaserToolbar />}
      mode="onBlur"
      reValidateMode="onBlur"
    >
      <FormHeader>{`${type} Sponsored Content Teaser, Targeting, and Pacing`}</FormHeader>

      <CampaignLineItemsSelectInput campaignId={lineItem.campaign.id} />

      <CreativeDetailsSection />
      <FormDivider />

      <TargetingDetailsSection />
      <FormDivider />

      <PacingSection />
    </SimpleForm>
  );
};

const CreativeDetailsSection = () => (
  <>
    <FormSectionHeader>Creative Details</FormSectionHeader>
    <BooleanInput source="isApproved" label="Approved" defaultValue={false} validate={validateIsApproved} />
    <TextInput label="Title" source="title" resettable fullWidth multiline validate={required()} />
    <TextInput label="Company / Brand Name" source="companyName" fullWidth resettable validate={required()} />
    <CurrentLineItemDateInputGroup />
    <TextInput label="Content ID" source="contentId" fullWidth resettable validate={[required(), validateContentId]} />
  </>
);

const TargetingDetailsSection = () => (
  <>
    <FormSectionHeader>Targeting Details</FormSectionHeader>
    <RadioButtonGroupInput
      source="targetingType"
      choices={targetingTypeChoices}
      defaultValue={ClicksCreativeTargetingType.TargetList}
    />

    <FormDataConsumerWithTargeting />
  </>
);

const FormDataConsumerWithTargeting = () => (
  <FormDataConsumer>
    {({ formData }) => {
      const targetingType = formData.targetingType;

      if (targetingType === ClicksCreativeTargetingType.TargetList) {
        return <AutocompleteInput source="targetListIdsList" resource="targetLists" />;
      } else if (targetingType === ClicksCreativeTargetingType.RunOfSpecialty) {
        return (
          <>
            <AutocompleteInput resource="specialties" source="specialtyIdsList" validate={validateRunOfSpecialty} />
            <AutocompleteInput resource="professions" source="professionIdsList" />
          </>
        );
      }
      return null;
    }}
  </FormDataConsumer>
);

export const validateRunOfSpecialty = (value: number[], allValues: SponsoredContentTeaser) => {
  if (allValues.targetingType === ClicksCreativeTargetingType['RunOfSpecialty'] && value.length === 0) {
    return 'If Run Of Specialty targeting is selected, you must select at least one specialty';
  }
  return undefined;
};

const validateContentId = (value: string) => {
  const regex = /^[a-zA-Z0-9]+$/; // only allow alphanumeric characters
  if (!regex.test(value)) {
    return 'Content ID must contain only alphanumeric characters';
  }
  return undefined;
};

const validateIsApproved = (value: boolean, allValues: SponsoredContentTeaser) => {
  if (value && !allValues.contentId) {
    return 'Content ID must be valid to approve the teaser';
  }
  return undefined;
};

const PacingSection = () => (
  <>
    <FormSectionHeader>Pacing</FormSectionHeader>
    <SelectInput
      source="priority"
      validate={[required()]}
      defaultValue={8}
      helperText="Between 1 (Highest) and 16 (Lowest)"
      choices={range(1, 17).map(value => {
        return {
          id: value,
          name: `${value}`,
        };
      })}
    />
  </>
);
