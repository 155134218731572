import {
  List,
  Datagrid,
  FunctionField,
  Labeled,
  Show,
  TextField,
  ReferenceManyField,
  ReferenceField,
  CreateButton,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  useResourceContext,
} from 'react-admin';
import { useRecordContext } from 'react-admin';
import { titleize, singularize } from 'inflection';
import { Divider, CardContent, CardHeader } from '@mui/material';
import { Children, isValidElement, ReactNode } from 'react';
import { ESTDateField } from './custom-ra-fields/est-date-field';

import { PlacementsButton, GreenChip, YellowChip } from './shared';
import { PreviewFMAButton } from './fma-creatives';
import { PreviewClickButton } from './clicks-creatives';
import { LineItemExclusionLabelChips } from './exclusion-labels/exclusion-label-chips';
import { AddLineItemLabelButton } from './exclusion-labels/add-line-item-label-button';
import { LineItemRecord } from './campaigns';

export const LineItemList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <ESTDateField source="startDate" textAlign="center" />
      <ESTDateField source="endDate" textAlign="center" />
      <PlacementsButton />
    </Datagrid>
  </List>
);

export const LineItemShow = () => (
  <Show>
    <LineItemShowLayout>
      <TextField source="name" />
      <ReferenceField label="Campaign Name" source="campaign.id" reference="campaigns" link="show">
        <TextField source="name" />
      </ReferenceField>

      <TextField source="product.name" label="Product" />
      <TextField source="customFields.hcpData" label="HCP Data?" />
      <ESTDateField source="startDate" label="Line Item Start Date" textAlign="center" />
      <ESTDateField source="endDate" label="Line Item End Date" textAlign="center" />
      <FunctionField
        label="Line Item Exclusion Labels"
        render={(record: LineItemRecord) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 5 }}>
            <LineItemExclusionLabelChips record={record} deletionEnabled />
            <AddLineItemLabelButton />
          </div>
        )}
      />
    </LineItemShowLayout>
  </Show>
);

const LineItemShowLayout = ({ children }: LineItemShowLayoutProps) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return (
    <>
      <CardContent>
        <CardHeader title={`${titleize(singularize(resource ?? ''))} #${record?.id}`} />
        {Children.map(children, field =>
          field && isValidElement<any>(field) ? (
            <div key={field.props.source}>
              <Labeled source={field.props.source} fullWidth={field.props.fullWidth}>
                {field}
              </Labeled>
            </div>
          ) : null,
        )}
      </CardContent>
      <Divider />

      <ClicksCreativeDatagrid />
      <Divider />

      <FMACreativesDatagrid />
      <Divider />

      <SponsoredContentTeasersDatagrid />
      <Divider />

      <BannersDatagrid />
    </>
  );
};

const CreateResourceButton = ({ resourceToCreate }: { resourceToCreate: string }) => {
  const record = useRecordContext();
  return (
    <CreateButton
      color="primary"
      to={{
        pathname: `/${resourceToCreate}/create`,
        search: `?placementsLineItemId=${record?.id}`,
      }}
    />
  );
};

const Chip = ({ isApproved }: { isApproved: boolean }) => {
  return isApproved ? <GreenChip /> : <YellowChip />;
};

const ClicksCreativeDatagrid = () => (
  <CardContent>
    <CardHeader title="Clicks Creatives" action={<CreateResourceButton resourceToCreate="clicksCreatives" />} />
    <ReferenceManyField
      reference="clicksCreatives"
      target="placementsLineItemId"
      sort={{ field: 'startsAt', order: 'DESC' }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label="Flight / Segment Name" />
        <TextField source="title" label="Creative Text" />
        <ESTDateField source="startsAt" label="Start Date" />
        <ESTDateField source="endsAt" label="End Date" />
        <FunctionField
          source="targetingType"
          label="Targeting"
          render={record => titleize(record?.targetingType ?? '-')}
        />
        <TargetingDetail label="Targeting Details" />
        <FunctionField
          source="isApproved"
          label="Status"
          textAlign="center"
          render={record => <Chip isApproved={record?.isApproved} />}
        />
        <PreviewClickButton />
      </Datagrid>
    </ReferenceManyField>
  </CardContent>
);

const FMACreativesDatagrid = () => (
  <CardContent>
    <CardHeader title="FMA Creatives" action={<CreateResourceButton resourceToCreate="fmaCreatives" />} />
    <ReferenceManyField
      reference="fmaCreatives"
      target="placementsLineItemId"
      sort={{ field: 'startsAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label="Flight / Segment Name" />
        <ESTDateField source="startsAt" label="Start Date" />
        <ESTDateField source="endsAt" label="End Date" />
        <FunctionField
          source="isApproved"
          label="Status"
          textAlign="center"
          render={record => <Chip isApproved={record?.isApproved} />}
        />
        <PreviewFMAButton />
      </Datagrid>
    </ReferenceManyField>
  </CardContent>
);

const BannersDatagrid = () => (
  <CardContent>
    <CardHeader title="Banner Targeting" action={<CreateResourceButton resourceToCreate="bannerTargetings" />} />
    <ReferenceManyField
      reference="bannerTargetings"
      target="placementsLineItemId"
      sort={{ field: 'startsAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="targetListId" label="Target List ID" />
        <ReferenceField label={'Target List Name'} reference="targetLists" source="targetListId">
          <TextField source="name" />
        </ReferenceField>
        <ESTDateField source="startsAt" label="Start Date" />
        <ESTDateField source="endsAt" label="End Date" />
      </Datagrid>
    </ReferenceManyField>
  </CardContent>
);

export const SponsoredContentTeasersDatagrid = () => (
  <CardContent>
    <CardHeader
      title="Sponsored Content Teasers"
      action={<CreateResourceButton resourceToCreate="sponsoredContentTeasers" />}
    />
    <ReferenceManyField
      reference="sponsoredContentTeasers"
      target="placementsLineItemId"
      sort={{ field: 'startsAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" label="Title" />
        <TextField source="contentId" label="Content ID" />
        <ESTDateField source="startsAt" label="Start Date" />
        <ESTDateField source="endsAt" label="End Date" />
        <FunctionField
          source="targetingType"
          label="Targeting"
          render={record => record?.targetingType ? record.targetingType.replace(/([A-Z])/g, ' $1').trim() : '-'}
        />
        <FunctionField
          source="isApproved"
          label="Status"
          textAlign="center"
          render={record => <Chip isApproved={record?.isApproved} />}
        />
      </Datagrid>
    </ReferenceManyField>
  </CardContent>
);

const TargetingDetail = ({ label }: { label?: string }) => {
  const record = useRecordContext();

  const referenceArrayProps =
    record?.targetingType === 'TARGET_LIST'
      ? { reference: 'targetLists', source: 'targetListIdsList' }
      : { reference: 'specialties', source: 'specialtyIdsList' };

  return (
    <ReferenceArrayField label={label} {...referenceArrayProps}>
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  );
};

export interface LineItemShowLayoutProps {
  children: ReactNode;
}
