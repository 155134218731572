import {
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  TopToolbar,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { ESTDateField } from './custom-ra-fields/est-date-field';
import { SponsoredContentTeaserForm } from './forms/sponsored-content-teasers';
import { useSearchParams } from 'react-router-dom';
import { useTransformWithCampaignId } from './forms/hooks/useTransformWithCampaignId';

// return empty tool bar to keep the spacing consistent
const SponsoredContentTeaserListActions = () => <TopToolbar />;

export const SponsoredContentTeasersList = () => (
  <List actions={<SponsoredContentTeaserListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="placementsLineItemId" />
      <TextField source="title" />
      <TextField source="contentId" />
      <ESTDateField source="startsAt" />
      <ESTDateField source="endsAt" />
    </Datagrid>
  </List>
);

interface SponsoredContentTeaserCreateInitialValues {
  placementsLineItemId: string;
}

export const SponsoredContentTeaserCreate = () => {
  const [searchParams] = useSearchParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const placementsLineItemId = searchParams.get('placementsLineItemId') || '';
  const transform = useTransformWithCampaignId();

  const resource = useResourceContext();

  const record: SponsoredContentTeaserCreateInitialValues = {
    placementsLineItemId,
  };

  const onSuccess = () => {
    notify('Saved new Sponsored Content Teaser');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Create record={record} redirect="list" transform={transform} mutationOptions={{ onSuccess }}>
      <SponsoredContentTeaserForm type="Create" />
    </Create>
  );
};

export const SponsoredContentTeaserEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const onSuccess = ({ placementsLineItemId }: { placementsLineItemId: number }) => {
    notify('Saved Sponsored Content Teaser');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Edit redirect="list" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <SponsoredContentTeaserForm type="Update" />
    </Edit>
  );
};
