import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import {
  CampaignList,
  CampaignShow,
  ClicksCreativeEdit,
  ClicksCreativeCreate,
  LineItemShow,
  TargetListCreate,
  TargetListEdit,
  TargetListsList,
  FmaCreativeCreate,
  FmaCreativeEdit,
  BannerTargetingsCreate,
  BannerTargetingsEdit,
} from 'src/client/components';
import { SponsoredContentTeaserCreate, SponsoredContentTeaserEdit } from 'src/client/components/sponsored-content-teasers';
import { createPostgraphileDataProvider } from 'src/client/providers/postgraphile-data-provider';
import { PlacementsRestDataProvider } from 'src/client/providers/placements-rest-data-provider';
import { CompositeDataProvider } from 'src/client/providers/composite-data-provider';

import DescriptionIcon from '@mui/icons-material/DescriptionTwoTone';
import LineItemIcon from '@mui/icons-material/FormatListBulletedTwoTone';
import LinkIcon from '@mui/icons-material/LinkTwoTone';
import PeopleIcon from '@mui/icons-material/PeopleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import WebIcon from '@mui/icons-material/Web';
import FlipToFrontIcon from '@mui/icons-material/FlipToFrontTwoTone';
import { CSAdminLayout } from 'src/client/components/layout';
import { ApolloClient, HttpLink, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { useUser, useClerk, useAuth } from '@clerk/nextjs';

import { Admin, Resource, DataProvider, defaultTheme, defaultDarkTheme } from 'react-admin';
import { setContext } from '@apollo/client/link/context';
import { ExclusionLabelCreate, ExclusionLabelsList } from 'src/client/components/exclusion-labels';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS } from 'date-fns/locale';

const createDataProvider = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getToken: () => Promise<string | null>,
) => {
  const postGraphileDataProvider = await createPostgraphileDataProvider(apolloClient, getToken);
  const placementsDataProvider = new PlacementsRestDataProvider(
    process.env.NEXT_PUBLIC_PLACEMENTS_IO_API_BASE_URL!,
    getToken,
  );
  const compositeDataProvider = new CompositeDataProvider(
    [postGraphileDataProvider, placementsDataProvider],
    postGraphileDataProvider,
  );
  return compositeDataProvider;
};

const makeLink = (getToken: () => Promise<string | null>) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return token !== null
      ? {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
          },
        }
      : headers;
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    credentials: 'include',
  });

  return authLink.concat(httpLink);
};

const CSAdminHome: NextPage = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  const apolloClient = useApolloClient();
  const { isLoaded, isSignedIn, user } = useUser();
  const { signOut } = useClerk();
  const { getToken } = useAuth();

  useEffect(() => {
    if (!dataProvider && apolloClient) {
      (async () => {
        const dataProvider = await createDataProvider(apolloClient as ApolloClient<NormalizedCacheObject>, getToken);
        setDataProvider(() => dataProvider);
      })();
    } else if (apolloClient) {
      apolloClient.link = makeLink(getToken);
    }
  }, [dataProvider, apolloClient, getToken]);

  if (!dataProvider) {
    return null;
  }

  const authProvider = {
    // unused, currently
    checkError: async () => {},
    checkAuth: async () => {},
    login: async () => {},
    logout: async () => signOut(),
    getIdentity: async () => {
      if (!isLoaded || !isSignedIn || !user) {
        return Promise.reject('Not loaded or signed in');
      }
      return Promise.resolve({
        id: user.id,
        fullName: user.fullName ?? undefined,
        avatar: user.profileImageUrl,
      });
    },
    getPermissions: async () => 'admin',
    // ...
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
        <Admin
          title="CSAdmin"
          dataProvider={dataProvider}
          authProvider={authProvider}
          layout={CSAdminLayout}
          theme={defaultTheme}
          darkTheme={defaultDarkTheme}
          defaultTheme="light"
        >
          <Resource name="campaigns" list={CampaignList} show={CampaignShow} icon={DescriptionIcon} />
          <Resource name="line_items" show={LineItemShow} options={{ label: 'Line Items' }} icon={LineItemIcon} />
          <Resource
            name="sponsoredContentTeasers"
            create={SponsoredContentTeaserCreate}
            edit={SponsoredContentTeaserEdit}
            options={{ label: 'Sponsored Content Teasers' }}
            icon={DescriptionIcon}
          />
          <Resource
            name="clicksCreatives"
            create={ClicksCreativeCreate}
            edit={ClicksCreativeEdit}
            options={{ label: 'Clicks Creatives' }}
            icon={LinkIcon}
          />
          <Resource
            name="fmaCreatives"
            create={FmaCreativeCreate}
            edit={FmaCreativeEdit}
            options={{ label: 'FMA Creatives' }}
            icon={FlipToFrontIcon}
          />
          <Resource
            name="targetLists"
            list={TargetListsList}
            create={TargetListCreate}
            edit={TargetListEdit}
            options={{ label: 'Target Lists' }}
            icon={PeopleIcon}
          />
          <Resource
            name="bannerTargetings"
            create={BannerTargetingsCreate}
            edit={BannerTargetingsEdit}
            options={{ label: 'Banner Targeting' }}
            icon={WebIcon}
          />
          <Resource name="accounts" />
          <Resource name="specialties" />
          <Resource name="professions" />
          <Resource
            name="labels"
            list={ExclusionLabelsList}
            create={ExclusionLabelCreate}
            options={{ label: 'Exclusion Labels' }}
            icon={BlockIcon}
          />
        </Admin>
        <Footer />
      </LocalizationProvider>
    </div>
  );
};

const Footer = () => (
  <div
    style={{
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 100,
      padding: 6,
      color: '#666',
      textAlign: 'center',
    }}
  >
    Copyright {new Date().getFullYear()} MDLinx. All Rights Reserved.
  </div>
);

export default CSAdminHome;
