import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  HttpError,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import postgraphileDataProvider from 'ra-postgraphile';
import { DataProviderForResources } from './data-provider-for-resources';
import { DelegatingDataProvider } from './delegating-data-provider';
import {
  createClicksCreative,
  createFmaCreative,
  createTargetList,
  duplicateClicksCreative,
  updateClicksCreative,
  updateFmaCreative,
  updateTargetList,
  duplicateFmaCreative,
  CreateTargetListVariables,
  UpdateTargetListVariables,
  archiveLabel,
  createSponsoredContentTeaser,
  updateSponsoredContentTeaser,
} from './extended-data-provider-methods/mutations';
import { targetListsFilterByLineItemId } from './extended-data-provider-methods/queries';
import { ArchiveLabelMutationVariables, ClicksCreative, SponsoredContentTeaser } from 'src/generated/client';

class PostgraphileDataProvider extends DelegatingDataProvider implements DataProviderForResources {
  forResources(): string[] {
    return ['clicks_creatives'];
  }
}

export const createPostgraphileDataProvider = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getToken: () => Promise<string | null>,
) => {
  const dataProvider = await postgraphileDataProvider(apolloClient, {
    typeMap: {
      ClicksCreative: {
        expand: true,
      },
      FmaCreative: {
        expand: true,
      },
      TargetList: {
        expand: true,
      },
      SponsoredContentTeaser: {
        expand: true,
      },
    },
  });

  const extendedDataProvider = {
    ...dataProvider,
    create: async (resource: string, params: CreateParams<any>): Promise<CreateResult<any>> => {
      switch (resource) {
        case 'targetLists':
          return createTargetList(apolloClient, params.data as CreateTargetListVariables, getToken);
        case 'clicksCreatives':
          return createClicksCreative(apolloClient, params.data as ClicksCreative);
        case 'fmaCreatives':
          return createFmaCreative(apolloClient, params.data);
        case 'sponsoredContentTeasers':
          return createSponsoredContentTeaser(apolloClient, params.data as SponsoredContentTeaser);
        default:
          return dataProvider.create(resource, params);
      }
    },
    update: async (resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> => {
      switch (resource) {
        case 'targetLists':
          return updateTargetList(apolloClient, params.data as UpdateTargetListVariables, getToken);
        case 'clicksCreatives':
          return updateClicksCreative(apolloClient, params.data as ClicksCreative);
        case 'fmaCreatives':
          return updateFmaCreative(apolloClient, params.data);
        case 'archiveLabel':
          return archiveLabel(apolloClient, params.data as ArchiveLabelMutationVariables);
        case 'sponsoredContentTeasers':
          return updateSponsoredContentTeaser(apolloClient, params.data as SponsoredContentTeaser);
        default:
          return dataProvider.update(resource, params);
      }
    },
    getManyReference: async (
      resource: string,
      params: GetManyReferenceParams,
    ): Promise<GetManyReferenceResult<any>> => {
      if (resource === 'targetLists' && params.target === 'placementLineItemIdsList') {
        return targetListsFilterByLineItemId(apolloClient, params.id as string);
      }

      return dataProvider.getManyReference(resource, params);
    },
    duplicate: async ({ resource, id }: { resource: string; id: number }) => {
      if (resource === 'clicksCreatives') {
        return duplicateClicksCreative(apolloClient, {
          existingId: id,
        });
      } else if (resource === 'fmaCreatives') {
        return duplicateFmaCreative(apolloClient, {
          existingId: id,
        });
      }
    },
    delete: async (resource: string, params: DeleteParams): Promise<DeleteResult<any>> => {
      const { previousData } = params;
      if (resource === 'clicksCreatives' || resource === 'fmaCreatives') {
        if (previousData && (previousData.isApproved || previousData.hasActivityData)) {
          throw new HttpError(
            'Creative must be changed to Draft status, and must not have activity data in order to be deleted.',
            400,
          );
        }
      } else if (resource === 'targetLists') {
        if (
          previousData &&
          ((previousData?.placementLineItemIdsList && previousData.placementLineItemIdsList.length > 0) ||
            (previousData?.clicksCreativeIdsList && previousData.clicksCreativeIdsList.length > 0) ||
            (previousData?.fmaCreativeIdsList && previousData.fmaCreativeIdsList.length > 0))
        ) {
          throw new HttpError(
            `Target lists cannot be deleted if they are associated with one or more line items or creatives.
            Remove this target list from all associated line items and creatives first.`,
            400,
          );
        }
      }
      return dataProvider.delete(resource, params);
    },
  };

  return new PostgraphileDataProvider(extendedDataProvider);
};
